/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as AIChatPageLoader } from './AIChatPage/AIChatPage.duck';
import { loadData as AuthenticationPageLoader } from './AuthenticationPage/AuthenticationPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as BlogPageLoader } from './BlogPage/BlogPage.duck';
import { loadData as DoctorsPaymentPageLoader } from './DoctorsPaymentPage/DoctorsPaymentPage.duck';
import { loadData as DashboardPageLoader } from './DashboardPage/DashboardPage.duck';
import { loadData as SingleBlogPageLoader } from './SingleBlogPage/SingleBlogPage.duck';
import { loadData as MenopausePageLoader } from './MenopausePage/MenopausePage.duck';
import { loadData as OurMenopauseJourneyPageLoader } from './OurMenopauseJourneyPage/OurMenopauseJourneyPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as MeetingPageLoader } from './MeetingPage/MeetingPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as GoogleAuthPageLoader } from './GoogleAuthPage/GoogleAuthPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SubscriptionPageLoader } from './SubscriptionPage/SubscriptionPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as VouchersPageLoader } from './VouchersPage/VouchersPage.duck';
import { loadData as TermsOfServicePageLoader } from './TermsOfServicePage/TermsOfServicePage.duck';
import { loadData as InvoicesPageLoader } from './InvoicesPage/InvoicesPage.duck';
import { loadData as CustomerOnboardingPageLoader } from './CustomerOnboardingPage/CustomerOnboardingPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    AIChatPage: {
      loadData: AIChatPageLoader,
    },
    CustomerOnboardingPage: {
      loadData: CustomerOnboardingPageLoader,
    },
    AuthenticationPage: {
      loadData: AuthenticationPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    BlogPage: {
      loadData: BlogPageLoader,
    },
    DoctorsPaymentPage: {
      loadData: DoctorsPaymentPageLoader,
    },
    DashboardPage: {
      loadData: DashboardPageLoader,
    },
    MenopausePage: {
      loadData: MenopausePageLoader,
    },
    OurMenopauseJourneyPage: {
      loadData: OurMenopauseJourneyPageLoader,
    },

    SingleBlogPage: {
      loadData: SingleBlogPageLoader,
    },
    MeetingPage: {
      loadData: MeetingPageLoader,
    },
    GoogleAuthPage: {
      loadData: GoogleAuthPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    PrivacyPolicyPage: {
      loadData: PrivacyPolicyPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SubscriptionPage: {
      loadData: SubscriptionPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TermsOfServicePage: {
      loadData: TermsOfServicePageLoader,
    },
    VouchersPage: {
      loadData: VouchersPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    InvoicesPage: {
      loadData: InvoicesPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
