import React, { useRef, useState, lazy, Suspense, useEffect } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Icons from '../../components/Icons/Icons';
import Modal from '../../components/Modal/Modal'; // Ensure you import the Modal component
import { extractYouTubeVideoId } from '../../util/dataExtractors';
import { mixPanelAnalytics } from '../../analytics/handlers';

import './videoPlayer.css';
import css from './VimePlayer.module.css';

const Player = lazy(() => import('@vime/react').then(mod => ({ default: mod.Player })));
const DefaultUi = lazy(() => import('@vime/react').then(mod => ({ default: mod.DefaultUi })));
const Youtube = lazy(() => import('@vime/react').then(mod => ({ default: mod.Youtube })));
const ClickToPlay = lazy(() => import('@vime/react').then(mod => ({ default: mod.ClickToPlay })));
const Spinner = lazy(() => import('@vime/react').then(mod => ({ default: mod.Spinner })));
const Poster = lazy(() => import('@vime/react').then(mod => ({ default: mod.Poster })));
const DblClickFullscreen = lazy(() =>
  import('@vime/react').then(mod => ({ default: mod.DblClickFullscreen }))
);

const VideoPlayer = ({ videoURL = 'https://youtu.be/C10H3pLRm1I', poster, title, viewport }) => {
  const playerRef = useRef(null);
  const intl = useIntl();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInitialPlay, setIsInitialPlay] = useState(true); // Track first play
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

  const isMobileLayout = viewport.width > 0 && viewport.width < 1024;

  const handleOverlayClick = () => {
    mixPanelAnalytics.trackEvent('Video Played', { video_id: videoURL });
    setIsPlaying(true);
    setIsInitialPlay(false); // Mark as played
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const parentRef = useRef(null); // Ref for the parent element
  const [parentHeight, setParentHeight] = useState(undefined); // State to store parent's height
  const [causeEffect, setCauseEffect] = useState(0);

  useEffect(() => {
    parentRef.current = document.querySelector('.rs-modal-body');
    if (parentRef?.current) {
      const inlineMaxHeight = parentRef.current.style.maxHeight; // Access inline style directly

      if (inlineMaxHeight !== '') {
        setParentHeight(inlineMaxHeight); // Update state
      } else {
        setCauseEffect(causeEffect => causeEffect + 1);
      }
    }
  }, [parentRef.current, causeEffect, viewport.width]);

  return (
    <div id="video-container" className={classNames(css.videoContentWrapper)}>
      {!isPlaying ? (
        <div className={css.videoPoster}>
          <img
            style={{
              height: parentHeight,
            }}
            src={poster}
            alt={title}
            className={css.videoPoster}
            onClick={handleOverlayClick}
          />
          <div className={css.overlayIcon} onClick={handleOverlayClick}>
            <Icons name="play" />
          </div>
        </div>
      ) : (
        <div
          className={classNames(css.videoContainer, {
            [css.playingVideoContainer]: isPlaying,
          })}
        >
          <Suspense
            fallback={
              <div className={css.iconSpinner}>
                <span className={css.loader}></span>
              </div>
            }
          >
            <Player
              playsinline
              autoplay={isInitialPlay}
              ref={playerRef}
              onVmPausedChange={event => {
                if (event.detail && isPlaying) {
                  console.log('Video paused');
                  setIsPlaying(false);
                }
              }}
              onVmPlayingChange={event => {
                if (event.detail && !isPlaying) {
                  console.log('Video playing');
                  setIsPlaying(true);
                }
              }}
              onVmFullscreenChange={event => {
                if (event.detail) {
                  console.log('Entered fullscreen mode');
                } else {
                  console.log('Exited fullscreen mode');
                }
              }}
            >
              <Youtube
                videoId={extractYouTubeVideoId(videoURL)}
                showFullscreenControl={true}
                params={{
                  modestbranding: 1,
                  rel: 0,
                  showinfo: 0,
                  vq: 'hd1080', // Request 1080p quality
                }}
              />
              <DefaultUi>
                <ClickToPlay />
                <Spinner />
                <Poster />
                <DblClickFullscreen useOnMobile />
              </DefaultUi>
            </Player>
          </Suspense>
        </div>
      )}
      {isMobileLayout && (
        <div className={css.fullscreenTrigger} role="button" onClick={openModal}>
          {intl.formatMessage({
            id: 'VideoPlayer.showFullscreen',
          })}
        </div>
      )}
      {isMobileLayout && (
        <Modal
          id="VideoPlayerMobileModal"
          isOpen={isModalOpen}
          onClose={closeModal}
          onManageDisableScrolling={() => {}}
          usePortal
          isFullScreenVideoModal={true}
        >
          <div
            className={classNames(css.modalContent, {
              [css.rotatedVideo]: isModalOpen, // Add rotation class conditionally
            })}
          >
            <Suspense
              fallback={
                <div className={css.iconSpinner}>
                  <span className={css.loader}></span>
                </div>
              }
            >
              <Player playsinline autoplay ref={playerRef}>
                <Youtube
                  videoId={extractYouTubeVideoId(videoURL)}
                  showFullscreenControl={true}
                  params={{
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                    vq: 'hd1080', // Request 1080p quality
                  }}
                />
                <DefaultUi>
                  <ClickToPlay />
                  <Spinner />
                  <Poster />
                  <DblClickFullscreen useOnMobile />
                </DefaultUi>
              </Player>
            </Suspense>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default VideoPlayer;
