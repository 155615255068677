import mixpanel from 'mixpanel-browser';

export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window.gtag) {
      window.setTimeout(() => {
        window.gtag('event', 'page_view', {
          page_path: canonicalPath,
        });
      }, 300);
    }
  }
}

// MixPanel analytics class to track user actions
class MixPanelAnalyticsHandler {
  constructor() {}

  initialize() {
    // Initialize Mixpanel with fixed project token
    mixpanel.init('d91ef8dd1868f640848a61ac37193ebd');
  }

  identifyUser(userId) {
    mixpanel.identify(userId);
  }

  setUserData(user) {
    mixpanel.people.set({
      $email: user.email,
      $first_name: user.firstName,
      $last_name: user.lastName,
      $created: new Date(),
    });
  }

  trackEvent(eventName, properties = {}) {
    mixpanel.track(eventName, properties);
  }
}

export const mixPanelAnalytics = new MixPanelAnalyticsHandler();
