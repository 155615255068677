import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import pptShape1 from '../../assets/ppt-shape1.png';
import pptShape2 from '../../assets/ppt-shape2.png';
import bg from '../../assets/extraFeaturesBg.png';
import css from './LandingPage.module.css';

function SectionExtraFeatures() {
  return (
    <>
      <div className={css.ppt2} style={{ backgroundImage: `url(${bg})` }}>
        <div className={css.registerHealthcare}>
          <div className={css.contentWidth}>
            {[...Array(7).keys()].map((_, i) => (
              <p key={i} className={i === 0 ? css.featuresHeader : ''}>
                <FormattedMessage id={`LandingPage.register${i + 1}`} />
                <br />
                <br />
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionExtraFeatures;
