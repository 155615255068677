import moment from 'moment';
import { DEFAULT_BOOKING_OPTIONS, salutationOptions } from '../config/customConfig';
import { useConfiguration } from '../context/configurationContext';
import { USER_TYPE } from '../helpers/enums';
import { getDefaultLocale, isArrayLength } from './genericHelpers';
import { SENDGRID_CONTRACT_LIST } from './enums';

/**
 * Generates an array of image URLs using the base URL and an array of image objects.
 *
 * @param {Array} images - An array of image objects.
 * @param {string} url - The base URL for the images.
 * @returns {Array} An array of image URLs.
 * @throws {TypeError} If the `images` parameter is not an array.
 */
export const getImagesLink = (images, url) => {
  // Generate an array of image URLs using the base URL and the image object's URL attribute.
  const imageUrls =
    isArrayLength(images) &&
    images.map(image => {
      const imageUrl = image?.attributes?.url;
      return imageUrl;
    });

  // Return the array of image URLs.
  return imageUrls;
};

/**
 * Extracts relevant data from a blog post object.
 *
 * @param {Object} blog - The blog post object.
 * @returns {Object} An object containing the blog post title, description, and image URL.
 * @throws {Error} If the `blog` parameter is not a valid object.
 */
export const getBlogData = blog => {
  // Get the base URL from the configuration.
  const config = useConfiguration();
  const baseUrl = config?.strapi?.baseURL || '';

  // Get the blog images and generate image URLs using the base URL.
  const blogImages = blog?.attributes?.images?.data || [];
  const imagesLink = blogImages?.length && getImagesLink(blogImages, baseUrl);

  // Get the blog title and description.
  const title = blog?.attributes?.title || '';
  const description = blog?.attributes?.description || '';
  const tag = blog?.attributes?.tagGreek || blog?.attributes?.tagEnglish || '';
  const createdAt = blog?.attributes?.createdAt || '';

  // Return an object containing the blog title, description, and the first image URL (if available).
  return { title, description, tag, createdAt, image: imagesLink && imagesLink[0] };
};

export const isIndividualUser = user =>
  user?.attributes?.profile?.protectedData?.userType === USER_TYPE.INDIVIDUAL;

export const getTermsTimestamp = user => user?.attributes?.profile?.protectedData?.termsVersion;

export const getFullName = user => {
  return (
    user?.id && user?.attributes?.profile?.firstName + ' ' + user?.attributes?.profile?.lastName
  );
};

export const getUserGender = user => {
  const salutation = user?.id && user?.attributes?.profile?.protectedData?.salutation;
  const gender = salutationOptions.find(s => s.key === salutation);
  return gender?.type;
};

export const getFirstName = user => {
  return user?.id && user?.attributes?.profile?.firstName;
};

export const getLastName = user => {
  return user?.id && user?.attributes?.profile?.lastName;
};

export const getFirstAndLastName = (listing, user) => {
  const currentLocaleFromStorage = getDefaultLocale();

  const title =
    currentLocaleFromStorage === 'en'
      ? listing?.attributes?.publicData?.title_en
      : listing?.attributes?.title;
  const listingName = title
    ? { firstName: title?.split(' ')[0], lastName: title?.split(' ')[1] }
    : null;

  return title ? listingName : { firstName: getFirstName(user), lastName: getLastName(user) };
};

export const getCustomMeetingParams = user => {
  const displayName = user?.attributes?.profile?.displayName;
  const email = user?.attributes?.email;
  return { displayName, email };
};

export const getEmail = user => {
  return user?.id && user?.attributes?.email;
};
export const getSpeciality = user => {
  return user?.id && user?.attributes?.profile?.protectedData?.speciality;
};

export const isUsersStripeConnected = user => {
  return user?.id && user?.attributes?.profile?.publicData?.isStripeConnected;
};
export const hasValidSubscription = user => {
  return (
    user?.id &&
    (user?.attributes?.profile?.protectedData?.hasSubscribed ||
      user?.attributes?.profile?.privateData?.hasSubscribed ||
      user?.attributes?.profile?.protectedData?.walletAmount >= 0)
  );
};

export const canAccessDashboard = user => {
  return (
    user?.id &&
    (process.env.REACT_APP_ENV === 'development' ||
      user?.attributes?.email === 'getvivianlab@gmail.com')
  );
};

export const getListingImage = imgArr => {
  const firstImage = isArrayLength(imgArr) ? imgArr[0] : null;
  const image =
    firstImage?.attributes?.variants && firstImage?.attributes?.variants['listing-card']?.url;
  return image;
};

export const getSavedEventsData = listing => {
  return (listing?.id && listing?.attributes?.privateData?.eventsExtendedData) || [];
};

export const isInstantBooking = listing => {
  return listing?.id && listing?.attributes?.publicData?.instantBooking == 'yes';
};

export const getDisplayName = user => {
  return user?.id && user?.attributes?.profile?.displayName;
};

export const getTransactionServiceDetails = t => {
  return t?.id && t?.attributes?.protectedData?.service;
};
export const isTransactionWithCC = t => {
  return t?.attributes?.metadata?.isUnpaid === false || t?.attributes?.metadata?.isUnpaid === true;
};
export const getLastTransition = t => {
  return t?.id && t?.attributes?.lastTransition;
};

export const getServices = listing => {
  const currentLocaleFromStorage = getDefaultLocale();
  const services =
    currentLocaleFromStorage === 'en'
      ? listing?.attributes?.publicData?.en?.services
      : listing?.attributes?.publicData?.services;
  return (listing?.id && services) || [];
};

export const getCityCountry = listing => {
  const publicData = listing?.attributes?.publicData;
  return { country: publicData?.country, city: publicData?.city };
};

export const getGoogleAuthTokens = user => {
  return user?.id && user?.attributes?.profile?.protectedData?.googleAuthTokens;
};

export const isDoctorApproved = user => {
  return user?.id && user?.attributes?.profile?.protectedData?.approvalEmailSent;
};

export const getUserPreferredLang = user => {
  return user?.id && user?.attributes?.profile?.protectedData?.preferredLang;
};
export const isUserEmailVerified = user => {
  return user?.id && user?.attributes?.emailVerified;
};

export const getMeetingLink = tx => {
  return tx?.id && tx?.attributes?.metadata?.meetingLink;
};

export const getMeetingStart = tx => {
  return tx?.id && tx?.booking?.attributes?.start;
};

export const getMeetingEnd = tx => {
  return tx?.id && tx?.booking?.attributes?.end;
};

export const isCorporateCustomer = user => {
  return user?.attributes?.profile?.protectedData?.walletAmount;
};

export const getInvoiceUserData = user => {
  return {
    name: user?.attributes?.profile?.displayName,
  };
};

export const getMeetingTitle = tx => {
  const providerName = getDisplayName(tx?.provider);
  const customerName = getDisplayName(tx?.customer);

  return `Meeting | ${providerName} | ${customerName}`;
};

export const renameObjKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj)?.map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

function calculateInvoiceSummary(lineItems) {
  let subtotal = 0;
  let stripeFee = 0;

  // Calculate subtotal and stripe fee
  for (const item of lineItems) {
    if (item.code === 'line-item/hour') {
      subtotal += item.unitPrice.amount;
    } else if (item.code === 'line-item/stripe-fee') {
      stripeFee += item.lineTotal.amount;
    }
  }

  // Calculate total
  const total = subtotal + stripeFee;

  return {
    subtotal: subtotal / 100,
    stripeFee: stripeFee / 100,
    total: total / 100,
    vivianLabFee: 0,
  };
}

export const getReceiptData = tx => {
  const customer = tx?.customer;
  const provider = tx?.provider;
  const booking = tx?.booking;
  const protectedData = tx?.attributes?.protectedData;
  const duration = protectedData?.duration;
  const validLineItems =
    isArrayLength(tx?.attributes?.lineItems) && tx?.attributes?.lineItems?.filter(l => !l.reversal);

  const lineItems = calculateInvoiceSummary(validLineItems);

  const bookingOption = DEFAULT_BOOKING_OPTIONS.find(e => e.value === protectedData?.bookingType);
  const defaultLocale = (typeof window !== 'undefined' && localStorage.getItem('locale')) || 'de';

  return {
    customerName: getDisplayName(customer),
    providerName: getDisplayName(provider),
    date: moment(booking?.attributes?.start).format('MM/DD/YYYY hh:mm:ss'),
    type: defaultLocale === 'en' ? bookingOption?.label : bookingOption?.greekLabel,
    duration: defaultLocale === 'en' ? `${duration} minutes` : `${duration} λεπτά`,
    lineItems,
  };
};

export const getSavedReceiptData = tx => {
  return tx?.id && tx?.attributes?.metadata?.receiptData;
};

export const getTotalPayoutForDoctors = txs => {
  const allPrices = isArrayLength(txs)
    ? txs
        .filter(t => !t.attributes?.metadata?.isUnpaid)
        .map(tx => parseFloat(tx?.attributes?.protectedData?.service?.price))
        .reduce((acc, curr) => acc + curr, 0)
    : null;

  const totalPrice = allPrices?.toFixed(2);
  return totalPrice || 0;
};

export const getWalletBalance = user => {
  return user?.attributes?.profile?.protectedData?.walletAmount || 0;
};

export const getTutorialsData = t => {
  const title = t?.attributes?.title || '';
  const description = t?.attributes?.description || '';
  const doctorName = t?.attributes?.doctorName || '';
  const videoURL = t?.attributes?.videoURL || '';
  const tagEnglish = t?.attributes?.tagEnglish || [];
  const tagGreek = t?.attributes?.tagGreek || [];
  const rank = t?.attributes?.rank || 0;
  const slug = t?.attributes?.slug || 0;
  const createdAt = t?.attributes?.createdAt || null;
  const doctor = t?.attributes?.doctor || null;
  const thumbnail = t?.attributes?.thumbnail?.data?.attributes?.url;
  const moduleSlug = isArrayLength(t?.attributes?.tutorials_subcategories?.data)
    ? t?.attributes?.tutorials_subcategories?.data[0]?.attributes?.slug
    : null;

  return {
    title,
    doctorName,
    description,
    createdAt,
    thumbnail,
    videoURL,
    tagEnglish,
    tagGreek,
    rank,
    doctor,
    slug,
    moduleSlug,
  };
};

export const getUserFullName = user => {
  if (!user.id) return null;
  return user?.attributes?.profile?.publicData?.fullName || user?.attributes?.profile?.displayName;
};

export const getElorusInvoices = user => {
  if (!user?.id) return null;
  return user?.attributes?.profile?.privateData?.elorusInvoices;
};

export const getUserId = user => user?.id?.uuid || null;

export const isPartialPayment = tx => {
  return tx?.attributes?.metadata?.isPartialPayment;
};

export function extractYouTubeVideoId(url) {
  try {
    const shortUrlRegex = /https?:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})/;
    const longUrlRegex = /https?:\/\/(?:www\.)?youtube\.com\/.*v=([a-zA-Z0-9_-]{11})/;

    let match = url.match(shortUrlRegex);
    if (match && match[1]) {
      return match[1];
    }

    match = url.match(longUrlRegex);
    if (match && match[1]) {
      return match[1];
    }

    throw new Error('Invalid YouTube URL');
  } catch (error) {
    console.error(error);
    throw new Error('Invalid YouTube URL');
  }
}

export const getOwnVoucher = user => {
  return user?.attributes?.profile?.protectedData?.voucher;
};
export const getListIdBasedOnUserType = params => {
  const { userType, couponCode, isContentVoucher } = params || {};

  // Determine the list ID based on the conditions
  if (userType === 'individual') {
    if (
      couponCode &&
      (couponCode.toLowerCase().includes('bay') || couponCode.toLowerCase().includes('jde'))
    ) {
      return SENDGRID_CONTRACT_LIST.BAYER_AND_JDE;
    }
    if (!isContentVoucher) {
      return SENDGRID_CONTRACT_LIST.FREE_TRIAL;
    }
  }
};
