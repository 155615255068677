import React from 'react';
import { Drawer, Button, Placeholder } from 'rsuite';
import { useIntl } from 'react-intl';
import '../../styles/rsuite.min.css';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

const SliderDrawer = ({ open, onClose, content, title, isLoading }) => {
  const intl = useIntl();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      size={isMobile ? 'full' : 'lg'}
      placement={isMobile ? 'bottom' : 'right'}
    >
      <Drawer.Header>
        <Drawer.Title>
          {title ||
            intl.formatMessage({ id: 'Drawer.defaultTitle', defaultMessage: 'Default Title' })}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {isLoading ? (
          <Placeholder.Paragraph rows={16} />
        ) : (
          content ||
          intl.formatMessage({ id: 'Drawer.noContent', defaultMessage: 'No content provided' })
        )}
      </Drawer.Body>
      <Drawer.Actions>
        <Button onClick={onClose} appearance="primary">
          {intl.formatMessage({ id: 'Drawer.closeButton', defaultMessage: 'Close' })}
        </Button>
      </Drawer.Actions>
    </Drawer>
  );
};

export default SliderDrawer;
