export const PAGES = {
  LANDING_PAGE: '/',
  DOCTORS_PAGE: '/doctors',
  COMPANIES_PAGE: '/companies',
  BLOG_PAGE: '/blogs',
  ABOUT_PAGE: '/about',
  MENOPAUSE_PAGE: '/menopause',
  MENOPAUSE_LANDING_PAGE: '/home/menopause',
  SEXUAL_WELLNESS_PAGE: '/home/sexual-wellness',
  GALLERY_PAGE: '/gallery',
  CONTACT_PAGE: '/contact',
  AUTH_PAGE: '/signup',
  DASHBOARD_PAGE: '/dashboard',
  VOUCHERS_PAGE: '/vouchers',
};

export const BASE_COLORS = {
  PRIMARY: '#ee8b51',
};

export const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const USER_TYPE = {
  INDIVIDUAL: 'individual',
  DOCTOR: 'doctor',
  COMPANY: 'company',
};

export const DEFAULT_ENUMS = {
  INSTANT_BOOKING: 'instant-booking/release-1',
  EN: 'en',
  GR: 'gr',
};

export const SendGridTemplateIds = {
  CONTACT_US: 'd-75097bdc35da4272a421816d45b33a23',
  TERMINATION: 'd-a8352ad4b5c548d4ba26940034cc0fb3',
  DOCTOR_WARNING_GR: 'd-721981c241b14652a0d73611728b5410',
  DOCTOR_WARNING: 'd-e69edefaea954aa0b64f5cb6ae5332a8',
  APPLICATION_SUBMITTED_GR: 'd-34323388c975443297a001b13ff4e0d9',
  APPLICATION_SUBMITTED: 'd-a558b593c8cb42ab900aa2a1eea2d159',
  APPLICATION_APPROVED_GR: 'd-435fb700038d4419bc10736ff4c23e4b',
  APPLICATION_APPROVED: 'd-2840c461d1a04d8db2a18ef0cfcdb349',
  APPLICATION_NOTIFICATION: 'd-15f15009b1fa48a483661b75daa69fca',
};

export const videosData = [
  {
    title: 'New Methods for IVF',
    doctorTitle: 'Dr. Charis Chiniades',
    doctorGreekTitle: 'Δρ. Χαρης Χηνιαδης',
    greekTitle: 'Νέες εξελιξεις στην εξωσωματική γονιμοποιήση - Δρ. Χαρης Χηνιαδης',
    video:
      'https://dlbae70xl51ax.cloudfront.net/videos/10000000_238381265691589_8507958904356627149_n.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/videos/thumbnails/IMG_5955.jpg',
  },
  {
    title: 'Hormone and other therapies for menopause',
    greekTitle: 'Ορμονικές και άλλες θεραπείες για αντιμετώπιση της εμμηνοπαυσης',
    doctorTitle: 'Dr. Sabine Wunschmann',
    doctorGreekTitle: 'Δρ. Sabine Wunschmann',
    video:
      'https://dlbae70xl51ax.cloudfront.net/videos/10000000_787196719822155_968606421069196418_n.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/videos/thumbnails/IMG_5954.jpg',
  },
  {
    title: 'Annual exam for women & female cancer',
    greekTitle: 'Ετήσιος γυναικολογικός έλεγχος & Γυναικολογικοί καρκίνοι',
    doctorTitle: 'Dr. Manolis Kalampokas',
    doctorGreekTitle: 'Δρ. Μανώλης Καλαμπόκας',
    video:
      'https://dlbae70xl51ax.cloudfront.net/videos/10000000_1248213982473908_4060211198963701713_n.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/videos/thumbnails/IMG_5953.jpg',
  },
  {
    title: 'Fertility at every age',
    greekTitle: 'Γονιμότητα σε κάθε ηλικία',
    doctorTitle: 'Dr. Ioannis Kalogirou',
    doctorGreekTitle: 'Δρ. Ιωάννης Καλογήρου',
    video:
      'https://dlbae70xl51ax.cloudfront.net/videos/10000000_619331962857261_2864380510453753780_n.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/videos/thumbnails/IMG_5958.jpg',
  },
  {
    title: 'Egg Freezing',
    greekTitle: 'Κρυοσυντηρηση ωαρίων',
    doctorTitle: 'Dr. Panagiotis Psathas',
    doctorGreekTitle: 'Δρ. Παναγιώτης Ψαθάς',
    video:
      'https://dlbae70xl51ax.cloudfront.net/videos/10000000_606536904738780_8087932281387119675_n.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/videos/thumbnails/IMG_5959.jpg',
  },
  {
    title: 'Menopause: how to diagnose & what you need to know',
    greekTitle: 'Εμμηνόπαυση: διάγνωση & τι πρέπει να γνωρίζουμε',
    doctorTitle: 'Dr. Irini Lamprinoudaki',
    doctorGreekTitle: 'Δρ. Ειρηνη Λαμπρινουδακη',
    video:
      'https://dlbae70xl51ax.cloudfront.net/videos/10000000_1927618650948182_5340767544060316642_n.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/videos/thumbnails/IMG_5956.jpg',
  },
  {
    title: 'Face lifting',
    greekTitle: 'Ανόρθωση προσώπου & λαιμού',
    doctorTitle: 'Dr. George Skouras',
    doctorGreekTitle: 'Δρ. Γιώργος Σκούρας',
    video:
      'https://dlbae70xl51ax.cloudfront.net/videos/10000000_640748304777935_2559871251410074269_n.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/videos/thumbnails/IMG_5957.jpg',
  },
  {
    title: 'Everything you need to know about Endometriosis',
    greekTitle: 'Όλα για την ενδομητρίωση',
    doctorTitle: 'Nikos Vlahos, M.D., F.A.C.O.G',
    doctorGreekTitle: 'Νίκος Βλάχος, Καθηγητής Γυναικολογίας',
    video:
      'https://dlbae70xl51ax.cloudfront.net/Snapinsta.app_video_GICWmADZq0--1NEDAA27SSBkcZJKbqCBAAAF.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/IMG_6861+(1).jpg',
  },
  {
    title: 'Psychology during Menopause',
    greekTitle: 'Ψυχικές διαταραχές στην κλιμακτήριο και την εμμηνόπαυση',
    doctorTitle: 'Yannis Zervas, Professor of Psychiatry',
    doctorGreekTitle: 'Γιάννης Ζέρβας, Καθηγητής Ψυχιατρικής',
    video:
      'https://dlbae70xl51ax.cloudfront.net/Snapinsta.app_video_GICWmAAkb7Ij5tAAAHbzioBMdEMXbqCBAAAF.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/IMG_6862.jpg',
  },
  {
    title: 'Immunology & Fertility',
    greekTitle: 'Αυτοάνοσα & Γονιμότητα',
    doctorTitle: 'Petros Efthimiou, MD Reumatologist',
    doctorGreekTitle: 'Πέτρος Ευθυμίου, MD Ρευματολόγος',
    video:
      'https://dlbae70xl51ax.cloudfront.net/Snapinsta.app_video_GICWmABSujpMboUFAP_KviqUOHcfbqCBAAAF.mp4',
    thumb: 'https://dlbae70xl51ax.cloudfront.net/IMG_6860.jpg',
  },
];

export const NON_TRANSLATED_PATHS = ['/home', '/guides', '/events', '/event'];

export const CONTACT_INFO = {
  EMAIL: 'info@vivianlab.com',
  PHONE: '+30 21 1199 8304',
};
