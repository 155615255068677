import React from 'react';
import css from './LandingPage.module.css';
import leftImage from '../../assets/register1.png';
import rightImage from '../../assets/register2.png';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';

const SectionRegister = () => {
  const history = useHistory();

  const handleRegisterClick = userType => {
    const route = createResourceLocatorString(
      'AuthenticationPageSelector',
      routeConfiguration(),
      { type: userType },
      {}
    );
    history.push(route);
  };

  return (
    <section className={css.registerSection}>
      <div className={css.registerButtons}>
        <button
          className={`${css.registerButton} ${css.individualButton}`}
          onClick={() => handleRegisterClick('individual')}
        >
          <FormattedMessage id="LandingPage.registerAsIndividual" />
        </button>
        <button
          className={`${css.registerButton} ${css.expertButton}`}
          onClick={() => handleRegisterClick('doctor')}
        >
          <FormattedMessage id="LandingPage.registerAsExpert" />
        </button>
        <button
          className={`${css.registerButton} ${css.companyButton}`}
          onClick={() => handleRegisterClick('company')}
        >
          <FormattedMessage id="LandingPage.registerAsCompany" />
        </button>
      </div>
      <img src={leftImage} alt="Left decoration" className={css.leftImage} />
      <img src={rightImage} alt="Right decoration" className={css.rightImage} />
    </section>
  );
};

export default SectionRegister;
