import React from 'react';
import PropTypes from 'prop-types';

// External link that opens in a new tab/window, ensuring that the
// opened page doesn't have access to the current page.
//
// See: https://mathiasbynens.github.io/rel-noopener/
const ExternalLink = props => {
  const { children, disabled, onLinkClick = () => {}, ...rest } = props;

  const handleClick = e => {
    if (disabled) {
      e.preventDefault();
    }
    onLinkClick && onLinkClick();
  };

  return (
    <a
      {...rest}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        ...(disabled && { color: 'gray' }),
        cursor: disabled ? 'not-allowed' : 'pointer',
        textDecoration: disabled ? 'none' : 'underline',
      }}
    >
      {children}
    </a>
  );
};

ExternalLink.defaultProps = {
  children: null,
  disabled: false,
};

const { node } = PropTypes;

ExternalLink.propTypes = { children: node };

export default ExternalLink;
