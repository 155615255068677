import specialityImg1 from '../assets/speciality-image1.png';
import specialityImg2 from '../assets/speciality-image2.png';
import specialityImg3 from '../assets/speciality-image3.png';
import specialityImg4 from '../assets/speciality-image6.png';
import specialityImg5 from '../assets/speciality-image5.png';
import specialityImg6 from '../assets/speciality-image4.png';
import specialityImg7 from '../assets/speciality-image7.png';
import specialityImg8 from '../assets/speciality-image8.png';
import specialityImg9 from '../assets/speciality-image9.png';
import specialityImg10 from '../assets/booking-image1.png';
import specialityImg11 from '../assets/booking-image2.png';
import specialityImg12 from '../assets/booking-image4.png';
import specialityImgOther from '../assets/companies-bg.png';

import { USER_TYPE } from '../helpers/enums';

export const MENOPAUSE_PROGRAMS = [
  {
    value: 'test',
    label: 'Test title',
    greekLabel: 'Test title',
    img: specialityImg9,
  },
];

//These are used in section hero for landing page for individuals
export const SPECIALTIES_OPTIONS = [
  {
    value: 'pregnancy-postpartum',
    label: 'Pregnancy & Postpartum',
    greekLabel: 'Εγκυμοσύνη, Τοκετός & Λοχεία',
    img: specialityImg1,
    extraSearchOptions: [
      {
        title: 'Doula & Midwife',
        searchKey: 'doula-midwife',
        description:
          'Postpartum, lactation, IVF, hypnobirth, water birth, home birth, sex pain, STDs, contraception',
        greekTitle: 'Μαία',
        greekDescription:
          'Λοχεία, θηλασμός, εξωσωματική γονιμοποίηση, τοκετός στο νερό, τοκετός στο σπίτι, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, αντισύλληψη',
      },
      {
        title: 'Lactation Consultant',
        searchKey: 'lactation-consultant',
        description: 'Nursing, pumping, storing milk',
        greekTitle: 'Σύμβουλος θηλασμού',
        greekDescription: 'Θηλασμός, άντληση, αποθήκευση γάλακτος',
      },
      {
        title: 'OB/GYN',
        description:
          'IVF, fertility treatments, high-risk pregnancy, C-section, menopause, cancer, pain during sex, STDs, hormones, contraception, endometriosis, surrogacy, urology, uterus prolapse',
        searchKey: 'ob-gyn',
        greekTitle: 'Γυναικολόγος- Μαιευτήρας',
        greekDescription:
          'Εξωσωματική γονιμοποίηση, θεραπείες γονιμότητας,  εγκυμοσύνη υψηλού κινδύνου, καισαρική τομή, εμμηνόπαυση, καρκίνος,  πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, ορμόνες, αντισύλληψη, ενδομητρίωση, παρένθετη μητρότητα, ουρολογία, πρόπτωση μήτρας',
      },
      // {
      //   title: 'Sleep Coach',
      //   searchKey: 'sleep-coach',
      //   description: 'Sleep training, co-sleeping',
      //   greekTitle: 'Sleep coach',
      //   greekDescription: 'Εκπαίδευση ύπνου, co-sleeping',
      // },
      {
        title: 'Physiotherapist pelvic floor & myoskeletal',
        description:
          'Pelvic floor physiotherapy, diastasi recti physiotherapy, low back pain, neck pain, knee pain, clinical pilates, muscle pain , scoliosis, post surgery rehabilitation,  fractures , ankle sprain , elbow pain , wrist pain, bowel dysfunction, pelvic floor weakness',
        searchKey: 'pelvic-floor-myoskeletal',
        greekTitle: 'Φυσικοθεραπευτής πυελικού εδάφους & μυοσκελετικά',
        greekDescription:
          'Φυσιοθεραπεία πυελικού εδάφους, φυσιοθεραπεία διάστασης ορθών κοιλιακών μυών, πόνοι μέσης, πόνοι αυχένα, πόνοι στα γόνατα,  κλινικό pilates,  μυϊκός πόνος , σκολίωση ,  μετεγχειρητική αποκατάσταση, κατάγματα , διάστρεμμα αστραγάλου , πόνος στον αγκώνα , πόνος στον καρπό ,ακράτεια. δυσλειτουργία του εντέρου αδυναμία πυελικού εδάφους',
      },
      // {
      //   title: 'Genetics Counselor',
      //   searchKey: 'genetics-counselor',
      //   description: 'Autism, genetic diseases, amniocentesis',
      //   greekTitle: 'Γενετιστής',
      //   greekDescription: 'Αυτισμός, γενετικές παθήσεις, αμνιοκέντηση',
      // },
      // {
      //   title: 'Career Coach',
      //   searchKey: 'career-coach',
      //   description: 'Return to work, growth, postpartum, team leading',
      //   greekTitle: 'Σύμβουλος Καριέρας',
      //   greekDescription: 'Επιστροφή στην εργασία, ανάπτυξη, λοχεία, ηγεσία',
      // },
      {
        title: 'Life Coach',
        searchKey: 'life-coach',
        description: 'Stress, anxiety, parenting, wellness, mindfulness, CBT',
        greekTitle: 'Life coach',
        greekDescription:
          'Άγχος, στρες, ανατροφή των παιδιών, ευεξία, ενσυνειδητότητα, γνωστική συμπεριφορική θεραπεία',
      },
      {
        title: 'Psychologist',
        searchKey: 'psychologist',
        description:
          'Divorce, postpatrum depression, mourning/loss, gender dusphoria, menopause, stress, ADHD, bi-polar, sex therapy, couples therapy, couples therapy, grief, gender dysphoria, pain during intercourse, lack of sexual desire',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχειος κατάθλιψη,εμμηνόπαυση, πένθος, δυσφορία φύλου, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος, θεραπεία ζευγαριών, πένθος, δυσφορία φύλου, πόνος κατά την σεξουαλική επαφή, έλλειψη ερωτικής επιθυμίας',
      },
      {
        title: 'Psychiatrist',
        searchKey: 'psychiatrist',
        description:
          'Postpatrum depression. menopause, stress, ADHD, bi-polar, couples therapy, grief, gender dysphoria, pain during intercourse, lack of sexual desire',
        greekTitle: 'Ψυχίατρος',
        greekDescription:
          'Επιλόχειος κατάθλιψη, εμμηνόπαυση,άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, θεραπεία ζευγαριών, πένθος, δυσφορία φύλου, πόνος κατά την σεξουαλική επαφή, έλλειψη ερωτικής επιθυμίας',
      },
      {
        title: 'Nutritionist',
        searchKey: 'nutritionist',
        description: 'Postpatrum, menopause, PCOS, weight loss, disorders',
        greekTitle: 'Διατροφολόγος',
        greekDescription:
          'Λοχεία, εμμηνόπαυση, σύνδρομο πολυκυστικών ωοθηκών, απώλεια βάρους, διαταραχές',
      },
      {
        title: 'Embryologist',
        searchKey: 'embryologist',
        description: 'IVF, time-lapse, screening',
        greekTitle: 'Εμβρυολόγος',
        greekDescription: 'Εξωσωματική γονιμοποίηση, διαγνωστικός έλεγχος',
      },
      {
        title: 'Dentist',
        searchKey: 'dentist',
        description: 'Dentist',
        greekTitle: 'Οδοντίατρος',
        greekDescription: 'Οδοντίατρος',
      },
      {
        title: 'urologist',
        searchKey: 'urologist',
        description: 'Urologist',
        greekTitle: 'Ουρολόγος',
        greekDescription: 'Ουρολόγος',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'sexual-pleasure',
    label: 'Sexual pleasure',
    greekLabel: 'Σεξουαλική ικανοποίηση',
    img: specialityImg2,
    extraSearchOptions: [
      {
        title: 'OB/GYN',
        searchKey: 'ob-gyn',
        description:
          'Genital aesthetics, G-shot, O-shot, vaginal rejuvanation, laser therapies, vaginoplasty',
        greekTitle: 'Γυναικολόγος- Μαιευτήρας',
        greekDescription:
          'Εξωσωματική γονιμοποίηση, θεραπείες γονιμότητας, εγκυμοσύνη υψηλού κινδύνου, καισαρική τομή, εμμηνόπαυση, καρκίνος, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, ορμόνες, αντισύλληψη, ενδομητρίωση, παρένθετη μητρότητα, ουρολογία, πρόπτωση μήτρας',
      },
      {
        title: 'Sex coach',
        searchKey: 'sex-coach',
        description:
          'Orgasm, menopause, mourning/loss, gender dusphoria, couples therapy, pain during sex, lack of desire, menopause, divorce, postpatrum depression. menopause, stress, ADHD, bi-polar, sex therapy, couples therapy, CBT, addiction, teenager consulting, parenting consulting, sexual disorders, endometriosis, panic disorder, orgasm & sexual pleasure , couples therapy, grief, gender dysphoria, pain during intercourse, lack of sexual desire',
        greekTitle: 'Sex coach',
        greekDescription:
          'Οργασμός, εμμηνόπαυση, θεραπεία ζευγαριών, πένθος, δυσφορία φύλου, πόνος κατά την σεξουαλική επαφή, έλλειψη ερωτικής επιθυμίας, εμμηνόπαυση, θεραπεία ζευγαριών, πένθος, δυσφορία φύλου, πόνος κατά την σεξουαλική επαφή, έλλειψη ερωτικής επιθυμίας',
      },
      {
        title: 'Pshyciatrist',
        searchKey: 'psychiatrist',
        description:
          'Disorders, menopause, mourning/loss, gender dusphoria, panic attacks, sexual  disorders, stress, depression, addiction',
        greekTitle: 'Ψυχίατρος',
        greekDescription:
          'Διαταραχές, εμμηνόπαυση, πένθος, δυσφορία φύλου, κρίσεις πανικού, σεξουαλικές διαταραχές, άγχος, κατάθλιψη, εθισμός',
      },
      {
        title: 'Doula & Midwife',
        searchKey: 'doula-midwife',
        description:
          'Postpatrum, lactation, IVF, hypnobirth, water birth, home birth, sex pain, STDs, contraception',
        greekTitle: 'Μαία',
        greekDescription:
          'Λοχεία, θηλασμός, εξωσωματική γονιμοποίηση, τοκετός στο νερό, τοκετός στο σπίτι, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, αντισύλληψη',
      },
      {
        title: 'Psychologist',
        searchKey: 'psychologist',
        description:
          'Divorce, postpatrum depression, mourning/loss, gender dusphoria, menopause, stress, ADHD, bi-polar, sex therapy, couples therapy',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχειος κατάθλιψη,εμμηνόπαυση, πένθος, δυσφορία φύλου, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'Plastic surgeon',
        searchKey: 'plastic-surgeon',
        description:
          'Lifting, abdominoplasty, nose surgery, ears surgery, breast augmentation or lift, liposuction, vaginoplasty, gender affirmation surgery',
        greekTitle: 'Πλαστικός Χειρoυργός',
        greekDescription:
          'Ανόρθωση, κοιλιοπλαστική, ρινοπλαστικη, και ωτοπλαστικη, αυξητική στήθους, ανόρθωση στήθους, λιποαναρρόφηση, κολποπλαστική, εγχείρηση αλλαγής φύλου',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'menopause',
    label: 'Menopause',
    greekLabel: 'Εμμηνόπαυση',
    img: specialityImg3,
    extraSearchOptions: [
      {
        title: 'OB/GYN',
        searchKey: 'ob-gyn',
        description: 'Menopause, hormone therapy',
        greekTitle: 'Γυναικολόγος - Μαιευτήρας',
        greekDescription: 'Εμμηνόπαυση, θεραπεία ορμονών',
      },

      {
        title: 'Nutritionist',
        searchKey: 'nutritionist',
        description: 'Postpatrum, menopause, PCOS, weight loss, disorders',
        greekTitle: 'Διατροφολόγος',
        greekDescription:
          'Λοχεία, εμμηνόπαυση, σύνδρομο πολυκυστικών ωοθηκών, απώλεια βάρους, διαταραχές',
      },
      {
        title: 'Psychologist',
        searchKey: 'psychologist',
        description:
          'Divorce, postpatrum depression, mourning/loss, gender dusphoria, menopause, stress, ADHD, bi-polar, sex therapy, couples therapy',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχειος κατάθλιψη,εμμηνόπαυση, πένθος, δυσφορία φύλου, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'Life coach',
        searchKey: 'life-coach',
        description: 'Transitions, wellness, meditation, stress, CBT',
        greekTitle: 'Life coach',
        greekDescription:
          'Άγχος, στρες, ανατροφή των παιδιών, ευεξία, ενσυνειδητότητα, γνωστική συμπεριφορική θεραπεία',
      },
      {
        title: 'Doula & Midwife',
        searchKey: 'doula-midwife',
        description:
          'Postpatrum, lactation, IVF, hypnobirth, water birth, home birth, sex pain, STDs, contraception',
        greekTitle: 'Μαία',
        greekDescription:
          'Λοχεία, θηλασμός, εξωσωματική γονιμοποίηση, τοκετός στο νερό, τοκετός στο σπίτι, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, αντισύλληψη',
      },
      // {
      //   title: 'Homeopathist - Chinese medicine - Reiki',
      //   searchKey: 'homeopathist-chinese-medicine',
      //   description: '',
      //   greekTitle: 'Ομοιοπαθητικός - Κινέζικη Ιατρική',
      //   greekDescription: '',
      // },
      // {
      //   title: 'Acupuncturist',
      //   searchKey: 'acupuncturist',
      //   description: '',
      //   greekTitle: 'Βελονιστής',
      //   greekDescription: '',
      // },
      {
        title: 'Pshyciatrist',
        searchKey: 'psychiatrist',
        description:
          'Disorders, menopause, mourning/loss, gender dusphoria, panic attacks, sexual  disorders, stress, depression, addiction',
        greekTitle: 'Ψυχίατρος',
        greekDescription:
          'Επιλόχειος κατάθλιψη, πένθος, δυσφορία φύλου, εμμηνόπαυση,άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή',
      },
      {
        title: 'Dermatologist - Venereologist',
        searchKey: 'dermatologist-venereologist',
        description: 'Botox, vaginal dryness, STDs, antiaging',
        greekTitle: 'Δερματολόγος - Αφροδισιολόγος',
        greekDescription:
          'Μπότοξ, ξηρότητα του κόλπου, σεξουαλικώς μεταδιδόμενα νοσήματα, αντιγήρανση',
      },
      {
        title: 'Endocrinologist',
        searchKey: 'endocrinologist',
        description: 'Menopause, hormones, diabetes, theroid',
        greekTitle: 'Ενδοκρινολόγος',
        greekDescription: 'Εμμηνόπαυση, ορμόνες, διαβήτης, θυρεοειδής',
      },
      // {
      //   title: 'Career Coach',
      //   searchKey: 'career-coach',
      //   description: 'Return to work, growth, pospatrum, team leading',
      //   greekTitle: 'Σύμβουλος καριέρας',
      //   greekDescription: 'Επιστροφή στην εργασία, ανάπτυξη, λοχεία, ηγεσία',
      // },
      {
        title: 'Plastic surgeon',
        searchKey: 'plastic-surgeon',
        description:
          'Lifting, abdominoplasty, breast augmentation or lift, liposuction, vaginoplasty, gender affirmation surgery',
        greekTitle: 'Πλαστικός Χειρoυργός',
        greekDescription:
          'Ανόρθωση, κοιλιοπλαστική, αυξητική στήθους, ανόρθωση στήθους, λιποαναρρόφηση, κολποπλαστική, εγχείρηση αλλαγής φύλου',
      },
      {
        title: 'Orthopaedic',
        searchKey: 'orthopaedic',
        description: '',
        greekTitle: 'Ορθοπαιδικός',
        greekDescription: '',
      },
      {
        title: 'Neurologist',
        searchKey: 'neurologist',
        description: '',
        greekTitle: 'Νευρολόγος',
        greekDescription: '',
      },
      {
        title: 'Cardiologist',
        searchKey: 'cardiologist',
        description: '',
        greekTitle: 'Καρδιολόγος',
        greekDescription: '',
      },
      {
        title: 'Dentist',
        searchKey: 'dentist',
        description: 'Dentist',
        greekTitle: 'Οδοντίατρος',
        greekDescription: 'Οδοντίατρος',
      },
      {
        title: 'urologist',
        searchKey: 'urologist',
        description: 'Urologist',
        greekTitle: 'Ουρολόγος',
        greekDescription: 'Ουρολόγος',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'endometriosis-chronic-pain',
    label: 'Endometriosis & Chronic Pain',
    greekLabel: 'Ενδομητρίωση & Χρόνιος πόνος',
    img: specialityImg4,
    extraSearchOptions: [
      {
        title: 'OB/GYN',
        searchKey: 'ob-gyn',
        description:
          'IVF, fertility treatments, high-risk pregnancy, C-section, menopause, cancer, pain during sex, STDs, hormones, contraception, endometriosis, surrogacy, urology, uterus prolapse',
        greekTitle: 'Γυναικολόγος- Μαιευτήρας',
        greekDescription:
          'Εξωσωματική γονιμοποίηση, θεραπείες γονιμότητας, εγκυμοσύνη υψηλού κινδύνου, καισαρική τομή, εμμηνόπαυση, καρκίνος, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, ορμόνες, αντισύλληψη, ενδομητρίωση, παρένθετη μητρότητα, ουρολογία, πρόπτωση μήτρας',
      },
      {
        title: 'Nutritionist',
        searchKey: 'nutritionist',
        description: 'Postpatrum, menopause, PCOS, weight loss, disorders',
        greekTitle: 'Διατροφολόγος',
        greekDescription:
          'Λοχεία, εμμηνόπαυση, σύνδρομο πολυκυστικών ωοθηκών, απώλεια βάρους, διαταραχές',
      },
      {
        title: 'Psychologist',
        searchKey: 'psychologist',
        description:
          'Divorce, postpatrum depression, mourning/loss, gender dusphoria, menopause, stress, ADHD, bi-polar, sex therapy, couples therapy',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχειος κατάθλιψη,εμμηνόπαυση, πένθος, δυσφορία φύλου, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'Life coach',
        description: 'Stress, anxiety, parenting, wellness, mindfulness, CBT',
        greekTitle: 'Life coach',
        searchKey: 'life-coach',
        greekDescription:
          'Άγχος, στρες, ανατροφή των παιδιών, ευεξία, ενσυνειδητότητα, γνωστική συμπεριφορική θεραπεία',
      },
      {
        title: 'Doula & Midwife',
        description:
          'Postpatrum, lactation, IVF, hypnobirth, water birth, home birth, sex pain, STDs, contraception',
        searchKey: 'doula-midwife',
        greekTitle: 'Μαία',
        greekDescription:
          'Λοχεία, θηλασμός, εξωσωματική γονιμοποίηση, τοκετός στο νερό, τοκετός στο σπίτι, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, αντισύλληψη',
      },
      // {
      //   title: 'Homeopathist - Chinese medicine',
      //   searchKey: 'homeopathist-chinese-medicine',
      //   description: '',
      //   greekTitle: 'Ομοιοπαθητικός - Κινέζικη Ιατρική',
      //   greekDescription: '',
      // },
      // {
      //   title: 'Acupuncturist',
      //   searchKey: 'acupuncturist',
      //   description: '',
      //   greekTitle: 'Βελονιστής',
      //   greekDescription: '',
      // },
      {
        title: 'Psychiatrist',
        searchKey: 'psychiatrist',
        description: 'Postpatrum depression. menopause, stress, ADHD, bi-polar',
        greekTitle: 'Ψυχίατρος',
        greekDescription:
          'Επιλόχειος κατάθλιψη, εμμηνόπαυση,άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή',
      },
      // {
      //   title: 'Reiki expert',
      //   searchKey: 'reiki-expert',
      //   description: '',
      //   greekTitle: 'Ειδικός Reiki',
      //   greekDescription: '',
      // },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'ivf-family-planning',
    label: 'Fertility, IVF & Family Planning',
    greekLabel: 'Γονιμότητα, Εξωσωματική Γονιμοποίηση & Υιοθεσία',
    img: specialityImg5,
    extraSearchOptions: [
      {
        title: 'OB/GYN',
        searchKey: 'ob-gyn',
        description:
          'IVF, fertility treatments, high-risk pregnancy, C-section, menopause, cancer, pain during sex, STDs, hormones, contraception, endometriosis, surrogacy, urology, uterus prolapse',
        greekTitle: 'Γυναικολόγος- Μαιευτήρας',
        greekDescription:
          'Εξωσωματική γονιμοποίηση, θεραπείες γονιμότητας, εγκυμοσύνη υψηλού κινδύνου, καισαρική τομή, εμμηνόπαυση, καρκίνος, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, ορμόνες, αντισύλληψη, ενδομητρίωση, παρένθετη μητρότητα, ουρολογία, πρόπτωση μήτρας',
      },
      // {
      //   title: 'Genetics Counselor',
      //   searchKey: 'genetics-counselor',
      //   description: 'Autism, genetic diseases, amniocentesis',
      //   greekTitle: 'Γενετιστής',
      //   greekDescription: 'Αυτισμός, γενετικές παθήσεις, αμνιοπαρακέντηση',
      // },
      {
        title: 'Nutritionist',
        searchKey: 'nutritionist',
        description: 'Postpatrum, menopause, PCOS, weight loss, disorders',
        greekTitle: 'Διατροφολόγος',
        greekDescription:
          'Λοχεία, εμμηνόπαυση, σύνδρομο πολυκυστικών ωοθηκών, απώλεια βάρους, διαταραχές',
      },
      {
        title: 'Life coach',
        description: 'Stress, anxiety, parenting, wellness, mindfulness, CBT',
        searchKey: 'life-coach',
        greekTitle: 'Life coach',
        greekDescription:
          'Άγχος, στρες, ανατροφή των παιδιών, ευεξία, ενσυνειδητότητα, γνωστική συμπεριφορική θεραπεία',
      },
      {
        title: 'Psychologist',
        description:
          'Divorce, postpatrum depression, mourning/loss, gender dusphoria, menopause, stress, ADHD, bi-polar, sex therapy, couples therapy',
        searchKey: 'psychologist',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχειος κατάθλιψη,εμμηνόπαυση, πένθος, δυσφορία φύλου, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'Psychiatrist',
        description: 'Postpatrum depression. menopause, stress, ADHD, bi-polar',
        searchKey: 'psychiatrist',
        greekTitle: 'Ψυχίατρος',
        greekDescription:
          'Επιλόχειος κατάθλιψη, εμμηνόπαυση,άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή ',
      },
      {
        title: 'Embryologist',
        searchKey: 'embryologist',
        description: '',
        greekTitle: 'Εμβρυολόγος',
        greekDescription: '',
      },
      {
        title: 'Endocrinologist',
        searchKey: 'endocrinologist',
        description: 'Menopause, hormones, diabetes, theroid',
        greekTitle: 'Ενδοκρινολόγος',
        greekDescription: 'Εμμηνόπαυση, ορμόνες, διαβήτης, θυρεοειδής',
      },
      {
        title: 'Reproductive rheumatologist',
        searchKey: 'reproductive-rheumatologist',
        description: '',
        greekTitle: 'Ρευματολόγος Αναπαραγωγής',
        greekDescription: '',
      },
      {
        title: 'Adoption advisor',
        searchKey: 'adoption-divorce-lawyer',
        description: 'adoption, surrogacy, egg donation, divorce',
        greekTitle: 'Δικηγόρος Υιοθεσίας & Διαζυγίου',
        greekDescription: 'αναδοχή,  δωρεά ωαρίων, παρένθετη μητρότητα, υιοθεσία. διαζύγιο',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'womens-health-abortion',
    label: 'Women’s Health & Abortion',
    greekLabel: 'Γυναικολογική υγεία, Επεμβάσεις & Διακοπή Κύησης',
    img: specialityImg7,
    extraSearchOptions: [
      {
        title: 'Doula & Midwife',
        description:
          'Postpatrum, lactation, IVF, hypnobirth, water birth, home birth, sex pain, STDs, contraception',
        greekTitle: 'Μαία',
        greekDescription:
          'Λοχεία, θηλασμός, εξωσωματική γονιμοποίηση, τοκετός στο νερό, τοκετός στο σπίτι, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, αντισύλληψη',
        searchKey: 'doula-midwife',
      },
      {
        title: 'OB/GYN',
        description:
          'IVF, fertility treatments, high-risk pregnancy, C-section, menopause, cancer, pain during sex, STDs, hormones, contraception, endometriosis, surrogacy, urology, uterus prolapse',
        greekTitle: 'Γυναικολόγος- Μαιευτήρας',
        searchKey: 'ob-gyn',
        greekDescription:
          'Εξωσωματική γονιμοποίηση, θεραπείες γονιμότητας, εγκυμοσύνη υψηλού κινδύνου, καισαρική τομή, εμμηνόπαυση, καρκίνος, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, ορμόνες, αντισύλληψη, ενδομητρίωση, παρένθετη μητρότητα, ουρολογία, πρόπτωση μήτρας',
      },
      {
        title: 'Psychologist',
        description:
          'Divorce, postpatrum depression, mourning/loss, gender dusphoria, menopause, stress, ADHD, bi-polar, sex therapy, couples therapy',
        searchKey: 'psychologist',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχειος κατάθλιψη,εμμηνόπαυση, πένθος, δυσφορία φύλου, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'Mastologist',
        searchKey: 'mastologist',
        description: '',
        greekTitle: 'Μαστολόγος',
        greekDescription: '',
      },
      {
        title: 'Dermatologist - Venereologist',
        searchKey: 'Dermatologist',
        description: 'Botox, vaginal dryness, STDs, antiaging',
        greekTitle: 'Δερματολόγος - Αφροδισιολόγος',
        greekDescription:
          'Μπότοξ, ξηρότητα του κόλπου, σεξουαλικώς μεταδιδόμενα νοσήματα, αντιγήρανση',
      },
      {
        title: 'Physiotherapist pelvic floor & myoskeletal',
        description:
          'Pelvic floor physiotherapy, diastasi recti physiotherapy, low back pain, neck pain, knee pain, clinical pilates, muscle pain , scoliosis, post surgery rehabilitation,  fractures , ankle sprain , elbow pain , wrist pain, bowel dysfunction, pelvic floor weakness',
        searchKey: 'pelvic-floor-myoskeletal',
        greekTitle: 'Φυσικοθεραπευτής πυελικού εδάφους & μυοσκελετικά',
        greekDescription:
          'Φυσιοθεραπεία πυελικού εδάφους, φυσιοθεραπεία διάστασης ορθών κοιλιακών μυών, πόνοι μέσης, πόνοι αυχένα, πόνοι στα γόνατα,  κλινικό pilates,  μυϊκός πόνος , σκολίωση ,  μετεγχειρητική αποκατάσταση, κατάγματα , διάστρεμμα αστραγάλου , πόνος στον αγκώνα , πόνος στον καρπό ,ακράτεια. δυσλειτουργία του εντέρου αδυναμία πυελικού εδάφους',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'genital-aesthetics-plastic-surgery',
    label: 'Genital Aesthetics',
    greekLabel: 'Αισθητική Γυναικολογία',
    img: specialityImg11,
    extraSearchOptions: [
      {
        title: 'OB/GYN',
        searchKey: 'ob-gyn',
        description: 'G-shot, O-shot, vaginal rejuvantion, vaginal tightening, menopause',
        greekTitle: 'Γυναικολόγος',
        greekDescription:
          'G-shot, O-shot, κολπική αναζωογόνηση, σύσφιξη κόλπου, εμμηνόπαυση, αιδοιπλαστική',
      },
      {
        title: 'Sex coach',
        searchKey: 'sex-coach',
        description:
          'Orgasm, menopause, mourning/loss, gender dusphoria, couples therapy , pain during sex, lack of desire',
        greekTitle: 'Σεξολόγος',
        greekDescription:
          'οργασμός, εμμηνόπαυση, θεραπεία ζευγαριών, πένθος, δυσφορία φύλου, πόνος κατά την σεξουαλική επαφή, έλλειψη ερωτικής επιθυμίας',
      },
      {
        title: 'Plastic surgeon',
        searchKey: 'plastic-surgeon',
        description:
          'Lifting, abdominoplasty, breast augmentation or lift, liposuction, vaginoplasty, gender affirmation surgery   ',
        greekTitle: 'Πλαστικός Χειρoυργός',
        greekDescription:
          'Ανόρθωση, κοιλιοπλαστική, αυξητική στήθους, ανόρθωση στήθους, λιποαναρρόφηση, κολποπλαστική, εγχείρηση αλλαγής φύλου',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'antiaging-beauty',
    label: 'Antiaging & Beauty',
    greekLabel: 'Αντιγήρανση & Ομορφιά',
    img: specialityImg8,
    extraSearchOptions: [
      {
        title: 'Dermatologist - Venereologist',
        searchKey: 'Dermatologist',
        description: 'Botox, vaginal dryness, STDs, antiaging',
        greekTitle: 'Δερματολόγος - Αφροδισιολόγος',
        greekDescription:
          'Μπότοξ, ξηρότητα του κόλπου, σεξουαλικώς μεταδιδόμενα νοσήματα, αντιγήρανση',
      },
      {
        title: 'Plastic surgeon',
        searchKey: 'plastic-surgeon',
        description:
          'Lifting, abdominoplasty, breast augmentation or lift, liposuction, vaginoplasty, gender affirmation surgery',
        greekTitle: 'Πλαστικός Χειρουργός',
        greekDescription:
          'Λίφτινγκ, βλεφαροπλαστική, κοιλιοπλαστική, αυξητική στήθους, ανόρθωση στήθους, λιποαναρρόφηση, κολποπλαστική, εγχείρηση αλλαγής φύλου',
      },
      {
        title: 'Nutritionist',
        searchKey: 'nutritionist',
        description: 'Postpatrum, menopause, PCOS, weight loss, disorders',
        greekTitle: 'Διατροφολόγος',
        greekDescription:
          'Λοχεία, εμμηνόπαυση, σύνδρομο πολυκυστικών ωοθηκών, απώλεια βάρους, διαταραχές',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'pediatrics',
    label: 'Paediatrics & Child Pshychology',
    greekLabel: 'Παιδιατρικά θέματα & Παιδοψυχολογία',
    img: specialityImg6,
    extraSearchOptions: [
      {
        title: 'Paediatrician',
        searchKey: 'paediatrician',
        description: 'newborn care, genetic syndroms, flu',
        greekTitle: 'Παιδίατρος',
        greekDescription: 'Φροντίδα νεογέννητων, γενετικά σύνδρομα, γρίπη',
      },
      {
        title: 'Pediatric Surgeon',
        searchKey: 'pediatric-surgeon',
        description:
          'Pediatric surgery - Pediatric urology, Minimally invasive surgery, Laparoscopic and endoscopic surgery, Prenatal consultation of pediatric surgery and pediatric urological diseases',
        greekTitle: 'Χειρουργός Παίδων',
        greekDescription:
          'Παιδοχειρουργική - Παιδοουρολογία, Ελάχιστα επεμβατική χειρουργική, Λαπαροσκοπική και ενδοσκοπική χειρουργική, Προγεννητική συμβουλευτική παιδοχειρουργικών και παιδοουρολογικών παθήσεων',
      },
      {
        title: 'Social Worker',
        searchKey: 'social-worker',
        description:
          'Social welfare,delinquency,child protection, elderly care, rehabilitation. child abuse, immigrant support, single parent family',
        greekTitle: 'Κοινωνικός Λειτουργός',
        greekDescription:
          'Κοινωνική πρόνοια, παραβατικότητα, παιδική προστασία, φροντίδα ηλικιωμένων, απεξάρτηση, κακοποίηση παιδιών, υποστήριξη μεταναστών, μονογονεϊκή οικογένεια',
      },
      {
        title: 'Ergotherapist',
        searchKey: 'ergotherapist',
        description: 'ergotherapist',
        greekTitle: 'Εργοθεραπευτής',
        greekDescription: 'Εργοθεραπευτής',
      },
      {
        title: 'Child Psychologist',
        searchKey: 'child-psychologist',
        description: 'autism, tantrums, ADHD, school performance, anxiety, aggressiveness',
        greekTitle: 'Παιδοψυχολόγος',
        greekDescription:
          'Αυτισμός, έκρηξη θυμού, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, σχολική επίδοση, ανησυχία, επιθετικότητα',
      },
      {
        title: 'Child psychiatrist',
        greekTitle: 'Παιδοψυχίατρος',
        searchKey: 'childpsychiatrist',
        description:
          'autism, tantrums, ADHD, school performance, anxiety, aggressiveness, depression',
        greekDescription:
          'Αυτισμός, έκρηξη οργής, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, σχολική επίδοση, ανησυχία, επιθετικότητα, κατάθλιψη',
      },
      {
        title: 'Logotherapist',
        searchKey: 'logotherapist',
        description: 'school performance, ADHD, coordination',
        greekTitle: 'Λογοθεραπευτής',
        greekDescription:
          'Σχολική επίδοση,  διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, συντονισμός',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'postpartum-depression-mental-health',
    label: 'Mental Health, Relationships & Postpartum Depression',
    greekLabel: 'Ψυχική υγεία, Σχέσεις & Επιλόχειος κατάθλιψη',
    img: specialityImg9,
    extraSearchOptions: [
      // {
      //   title: 'Career Coach',
      //   searchKey: 'career-coach',
      //   description: 'menopause, return to work, growth, pospatrum, team leading',
      //   greekTitle: 'Σύμβουλος Καριέρας',
      //   greekDescription: 'Επιστροφή στην εργασία, ανάπτυξη, λοχεία, ηγεσία',
      // },
      {
        title: 'Life coach',
        searchKey: 'life-coach',
        description: 'menopause, stress, anxiety, parenting, wellness, mindfulness',
        greekTitle: 'Life coach',
        greekDescription:
          'Άγχος, στρες, ανατροφή των παιδιών, ευεξία, ενσυνειδητότητα, γνωστική συμπεριφορική θεραπεία',
      },
      {
        title: 'Psychologist',
        searchKey: 'psychologist',
        description: 'postpatrum depression. menopause, stress, ADHD, bi-polar',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχεια κατάθλιψη,εμμηνόπαυση, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'Psychiatrist',
        searchKey: 'psychiatrist',
        description: 'postpatrum depression. menopause, stress, ADHD, bi-polar',
        greekTitle: 'Ψυχίατρος',
        greekDescription:
          'Επιλόχειος κατάθλιψη, εμμηνόπαυση,άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή ',
      },
      {
        title: 'Sex coach',
        searchKey: 'sex-coach',
        description: 'orgasm, sexual disfunction, dispareunia, mourning/loss, gender dusphoria',
        greekTitle: 'Σεξολόγος',
        greekDescription:
          'οργασμός, εμμηνόπαυση, θεραπεία ζευγαριών, πένθος, δυσφορία φύλου, πόνος κατά την σεξουαλική επαφή, έλλειψη ερωτικής επιθυμίας',
      },
      {
        title: 'Mental Healt Advisor',
        searchKey: 'mental-health-advisor',
        description: 'postpatrum depression. menopause, stress, ADHD, bi-polar',
        greekTitle: 'Σύμβουλος Ψυχικής Υγείας',
        greekDescription:
          'Διαζύγιο, επιλόχεια κατάθλιψη,εμμηνόπαυση, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'cancer-oncology',
    label: 'Cancer & Oncology',
    greekLabel: 'Καρκίνος & Ογκολογία',
    img: specialityImgOther,
    extraSearchOptions: [
      {
        title: 'OB/GYN',
        searchKey: 'ob-gyn',
        description:
          'IVF, fertility treatments, high-risk pregnancy, C-section, menopause, cancer, pain during sex, STDs, hormones, contraception, endometriosis, surrogacy, urology, uterus prolapse',
        greekTitle: 'Γυναικολόγος- Μαιευτήρας',
        greekDescription:
          'Εξωσωματική γονιμοποίηση, θεραπείες γονιμότητας, εγκυμοσύνη υψηλού κινδύνου, καισαρική τομή, εμμηνόπαυση, καρκίνος, πόνος κατά την σεξουαλική επαφή, σεξουαλικώς μεταδιδόμενα νοσήματα, ορμόνες, αντισύλληψη, ενδομητρίωση, παρένθετη μητρότητα, ουρολογία, πρόπτωση μήτρας',
      },
      {
        title: 'Mastologist',
        searchKey: 'mastologist',
        description: '',
        greekTitle: 'Μαστολόγος',
        greekDescription: '',
      },
      {
        title: 'Oncologist',
        searchKey: 'oncologist',
        description: '',
        greekTitle: 'Ογκολόγος',
        greekDescription: '',
      },
      {
        title: 'Head & Neck Surgeon',
        searchKey: 'head-neck-surgeon',
        description:
          'Cancer, hpv, endocrinology surgery, oncology surgery, thyroid , skin cancer, melanoma,Thyroid and Parathyroid Gland Surgery, salivary gland surgery, Mout and pharynx syrgery, Robotic Surgery, Skin cancer surgery',
        greekTitle: 'Χειρουργός κεφαλής & τραχήλου',
        greekDescription:
          'καρκίνος, hpv , ενδοκρινολογία,  ογκολογία κεφαλής και τραχήλου, θυρεοειδής, καρκίνος δέρματος, μελάνωμα,Χειρουργική θυρεοειδή και παραθυρεοειδών αδένων,Χειρουργική σιελογόνων αδένων ,Χειρουργική στόματος, στοματοφάρυγγα και λάρυγγα,Ρομποτική χειρουργική,Χειρουργική καρκίνου του δέρματος ',
      },
      // {
      //   title: 'Reiki expert',
      //   searchKey: 'reiki-expert',
      //   description: '',
      //   greekTitle: 'Ειδικός Reiki',
      //   greekDescription: '',
      // },
      {
        title: 'Nutritionist',
        searchKey: 'nutritionist',
        description: 'Postpatrum, menopause, PCOS, weight loss, disorders',
        greekTitle: 'Διατροφολόγος',
        greekDescription:
          'Λοχεία, εμμηνόπαυση, σύνδρομο πολυκυστικών ωοθηκών, απώλεια βάρους, διαταραχές',
      },
      {
        title: 'Psychologist',
        searchKey: 'psychologist',
        description:
          'Divorce, postpatrum depression, mourning/loss, gender dusphoria, menopause, stress, ADHD, bi-polar, sex therapy, couples therapy',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχειος κατάθλιψη,εμμηνόπαυση, πένθος, δυσφορία φύλου, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'Psychiatrist',
        searchKey: 'psychiatrist',
        description: 'Postpatrum depression. menopause, stress, ADHD, bi-polar',
        greekTitle: 'Ψυχίατρος',
        greekDescription:
          'Επιλόχειος κατάθλιψη, εμμηνόπαυση,άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή ',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  {
    value: 'gender-exploration',
    label: 'Gender Exploration',
    greekLabel: 'Επαναπροσδιορισμός φύλου & ΛΟΑΤΚΙ',
    img: specialityImg12,
    extraSearchOptions: [
      {
        title: 'Psychologist',
        searchKey: 'psychologist',
        description:
          'Divorce, postpatrum depression, mourning/loss, gender dusphoria, menopause, stress, ADHD, bi-polar, sex therapy, couples therapy',
        greekTitle: 'Ψυχολόγος',
        greekDescription:
          'Διαζύγιο, επιλόχειος κατάθλιψη,εμμηνόπαυση, πένθος, δυσφορία φύλου, άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή, σεξουαλική θεραπεία, θεραπεία ζευγαριών, μεταβατική περίοδος',
      },
      {
        title: 'Psychiatrist',
        searchKey: 'psychiatrist',
        description: 'Postpatrum depression. menopause, stress, ADHD, bi-polar',
        greekTitle: 'Ψυχίατρος',
        greekDescription:
          'Επιλόχειος κατάθλιψη, εμμηνόπαυση,άγχος, διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας, διπολική διαταραχή',
      },
      {
        title: 'Plastic surgeon',
        searchKey: 'plastic-surgeon',
        description:
          'Lifting, abdominoplasty, breast augmentation or lift, liposuction, vaginoplasty, gender affirmation surgery',
        greekTitle: 'Πλαστικός Χειρουργός',
        greekDescription:
          'Λίφτινγκ, βλεφαροπλαστική, κοιλιοπλαστική, αυξητική στήθους, ανόρθωση στήθους, λιποαναρρόφηση, κολποπλαστική, εγχείρηση αλλαγής φύλου',
      },
      {
        title: 'Sex coach',
        searchKey: 'sex-coach',
        description:
          'Orgasm, menopause, couples therapy, mourning/loss, gender dusphoria, pain during sex, lack of desire',
        greekTitle: 'Σεξολόγος',
        greekDescription:
          'οργασμός, εμμηνόπαυση, θεραπεία ζευγαριών, πένθος, δυσφορία φύλου, πόνος κατά την σεξουαλική επαφή, έλλειψη ερωτικής επιθυμίας',
      },
      {
        title: 'Endocrinologist',
        searchKey: 'endocrinologist',
        description: 'Menopause, hormones, diabetes, theroid',
        greekTitle: 'Ενδοκρινολόγος',
        greekDescription: 'Εμμηνόπαυση, ορμόνες, διαβήτης, θυρεοειδής',
      },
      {
        title: 'General Practitioner',
        searchKey: 'general-practitioner',
        description:
          'vaccines, flu, COVID, strep, illnesses, diabetes, cough, stomach ache, headache, fever, asthma, cholesterol, UTI, respiratory infections, high blood pressure, sexually transmitted diseases.',
        greekTitle: 'Παθολόγος',
        greekDescription:
          'εμβόλια, γρίπη, COVID, στρεπτόκοκκος, ασθένειες, διαβήτης, βήχας, πόνος στη κοιλιά, πονοκέφαλος, πυρετός, άσθμα, χοληστερίνη, ουρολοίμωξη, λοιμώξεις αναπνευστικού, υψηλή πίεση, σεξουαλικώς μεταδιδόμενα νοσήματα',
      },
    ],
  },
  { value: 'all', label: 'All experts', greekLabel: 'Όλοι οι ειδικοί', img: specialityImg10 },
];

export const blogFilterGreek = [
  'Εγκυμοσύνη & Λοχεία',
  'Σεξουαλική ικανοποίηση',
  'Εμμηνόπαυση',
  'Ενδομητρίωση & Χρόνιες παθήσεις',
  'Εξωσωματική Γονιμοποίηση & Οικογένεια',
  'Γυναικολογική υγεία ',
  'Αισθητική Γυναικολογία & Πλαστική Χειρουργική',
  'Παιδιατρικά θέματα',
  'Ψυχική υγεία',
  'Υποστήριξη στην εργασία',
  'Vivian Lab News',
];

export const blogFilterEnglish = [
  'Pregnancy & Postpartum',
  'Sexual pleasure',
  'Menopause',
  'Endometriosis & Chronic Pain',
  'IVF & Family Planning',
  'Women’s Health ',
  'Genital Aesthetics & Plastic Surgery',
  'Pediatrics',
  'Mental Health',
  'Workplace & Support',
  'Vivian Lab News',
];

export const userTypeOptions = [
  {
    key: USER_TYPE.DOCTOR,
    icon: 'multiUser',
    label: 'Provider',
    greekLabel: 'Ιατρός & Ειδικός Υγείας',
  },
  { key: USER_TYPE.INDIVIDUAL, icon: 'singleUser', label: 'Individual', greekLabel: 'Χρήστης' },
];

export const salutationOptions = [
  { key: '', label: 'Select', greekLabel: 'Επιλέγω', disabled: true },
  { key: 'mr.', label: 'Mr.', greekLabel: 'Κύριος', type: 'male' },
  { key: 'mrs.', label: 'Mrs.', greekLabel: 'Κυρία', type: 'female' },
];

export const doctorSalutationOptions = [
  { key: '', label: 'Select', greekLabel: 'Επιλέγω', disabled: true },
  { key: 'dr.', label: 'Dr.', greekLabel: 'Δρ.' },
  { key: 'prof.', label: 'Prof.', greekLabel: 'Καθηγητής' },
  { key: 'mr.', label: 'Mr.', greekLabel: 'Κύριος', type: 'male' },
  { key: 'mrs.', label: 'Mrs.', greekLabel: 'Κυρία', type: 'female' },
];

export const genderOptions = [
  { key: '', label: 'Please select a gender', greekLabel: 'Επιλέξτε ένα φύλο', disabled: true },
  { key: 'male', label: 'Male', greekLabel: 'Άνδρας' },
  { key: 'female', label: 'Female', greekLabel: 'Γυναίκα' },
];

const sortOrder = [
  'Acupuncturist',
  'Adoption Advisor',
  'Cardiologist',
  'Career Coach',
  'Child Psychologist',
  'Child Psychiatrist',
  'Dentist',
  'Dermatologist - Venereologist',
  'Drama therapist',
  'Doula & Midwife',
  'Embryologist',
  'Ear nose and throat doctor (ENT)',
  'Endocrinologist',
  'Ergotherapist',
  'Genetics Counselor',
  'Head & neck surgeon',
  'Health & Wellness Coach',
  'Homeopathist - Chinese Medicine',
  'Gender Exploration Therapist',
  'General Surgeon',
  'General Practitioner',
  // 'IVF',
  'Lactation Consultant',
  'Life Coach',
  'Logotherapist',
  'Male Doula & Midwife',
  'Mental health advisor',
  'Mastologist',
  'Neurologist',
  'Nutritionist',
  'OB/GYN',
  'Oncologist',
  'Orthopaedic',
  'Paediatrician',
  'Pediatric Surgeon',
  'Physiotherapist pelvic floor & myoskeletal',
  'Plastic Surgeon',
  'Physiotherapist',
  'Psychiatrist',
  'Psychologist',
  'Reiki Expert',
  'Reproductive Rheumatologist',
  'Sex Coach',
  'Social Worker',
  'Sleep Coach',
  'Other',
  'Urologist',
];

//These are used in landing patient page
export const PATIENT_OPTIONS = [
  // {
  //   value: 'acupuncturist',
  //   label: 'Acupuncturist',
  //   greekLabel: 'Βελονιστής',
  // },
  {
    value: 'adoption-divorce-lawyer',
    label: 'Adoption Advisor Lawyer',
    greekLabel: 'Δικηγόρος Υιοθεσίας & Διαζυγίου',
  },
  {
    value: 'plastic-surgeon',
    label: 'Plastic Surgeon',
    greekLabel: 'Πλαστικός Χειρουργός',
  },
  {
    value: 'orthopaedic',
    label: 'Orthopaedic',
    greekLabel: 'Ορθοπεδικός',
  },
  {
    value: 'oncologist',
    label: 'Oncologist',
    greekLabel: 'Ογκολόγος',
  },
  {
    value: 'cardiologist',
    label: 'Cardiologist',
    greekLabel: 'Καρδιολόγος',
  },
  {
    value: 'mental-health-advisor',
    label: 'Mental health advisor',
    greekLabel: 'Σύμβουλος ψυχικής υγείας',
  },
  {
    value: 'health-wellness-coach',
    label: 'Health & Wellness Coach',
    greekLabel: 'Σύμβουλος Υγείας και Ευεξίας',
  },
  // {
  //   value: 'career-coach',
  //   label: 'Career Coach',
  //   greekLabel: 'Σύμβουλος Καριέρας',
  // },
  {
    value: 'pediatric-surgeon',
    label: 'Pediatric Surgeon',
    greekLabel: 'Χειρουργός Παίδων',
  },
  {
    value: 'health-and-wellness-coach',
    label: 'Health & Wellness Coach',
    greekLabel: 'Σύμβουλος Υγείας και Ευεξίας',
  },
  {
    value: 'social-worker',
    label: 'Social Worker',
    greekLabel: 'Κοινωνικός Λειτουργός',
  },
  {
    value: 'general-practitioner',
    label: 'General Practitioner',
    greekLabel: 'Παθολόγος',
  },
  {
    value: 'head-neck-surgeon',
    label: 'Head & neck surgeon',
    greekLabel: 'Χειρουργός κεφαλής & τραχήλου',
  },
  {
    value: 'drama-therapist',
    label: 'Drama therapist',
    greekLabel: 'Δραματοθεραπεύτρια',
  },
  {
    value: 'general-surgeon',
    label: 'General Surgeon',
    greekLabel: 'Γενικός Χειρουργός',
  },
  {
    value: 'life-coach',
    label: 'Life Coach',
    greekLabel: 'Σύμβουλος Ζωής',
  },
  {
    value: 'sex-coach',
    label: 'Sex Coach',
    greekLabel: 'Σύμβουλος Σεξουαλικότητας',
  },
  // {
  //   value: 'ivf',
  //   label: 'IVF',
  //   greekLabel: 'εξωσωματική γονιμοποίηση - σπερματέγχυση',
  // },
  // {
  //   value: 'sleep-coach',
  //   label: 'Sleep Coach',
  //   greekLabel: 'Σύμβουλος Ύπνου',
  // },
  {
    value: 'doula-midwife',
    label: 'Doula & Midwife',
    greekLabel: 'Μαία',
  },
  {
    value: 'doula-midwife-male',
    label: 'Male Doula & Midwife',
    greekLabel: 'Μαιευτής',
  },
  {
    value: 'dermatologist-venereologist',
    label: 'Dermatologist - Venereologist',
    greekLabel: 'Δερματολόγος - Αφροδισιολόγος',
  },
  {
    value: 'nutritionist',
    label: 'Nutritionist',
    greekLabel: 'Διαιτολόγος',
  },
  {
    value: 'endocrinologist',
    label: 'Endocrinologist',
    greekLabel: 'Ενδοκρινολόγος',
  },
  {
    value: 'ergotherapist',
    label: 'Ergotherapist',
    greekLabel: 'Εργοθεραπευτής',
  },
  {
    value: 'embryologist',
    label: 'Embryologist',
    greekLabel: 'Εμβρυολόγος',
  },
  {
    value: 'logotherapist',
    label: 'Logotherapist',
    greekLabel: 'Λογοθεραπευτής',
  },
  {
    value: 'mastologist',
    label: 'Mastologist',
    greekLabel: 'Μαστολόγος',
  },
  {
    value: 'ob-gyn',
    label: 'OB/GYN',
    greekLabel: 'Γυναικολόγος',
  },
  {
    value: 'neurologist',
    label: 'Neurologist',
    greekLabel: 'Νευρολόγος',
  },
  {
    value: 'paediatrician',
    label: 'Paediatrician',
    greekLabel: 'Παιδίατρος',
  },
  {
    value: 'childpsychiatrist',
    label: 'Child Psychiatrist',
    greekLabel: 'Παιδοψυχίατρος',
  },
  {
    value: 'child-psychologist',
    label: 'Child Psychologist',
    greekLabel: 'Παιδοψυχολόγος',
  },
  {
    value: 'psychiatrist',
    label: 'Psychiatrist',
    greekLabel: 'Ψυχίατρος',
  },
  {
    value: 'psychologist',
    label: 'Psychologist',
    greekLabel: 'Ψυχολόγος',
  },
  {
    value: 'physiotherapist',
    label: 'Physiotherapist',
    greekLabel: 'Φυσιοθεραπευτής',
  },
  {
    value: 'ent',
    label: 'Ear nose and throat doctor (ENT)',
    greekLabel: 'Ωτορινολαρυγγολόγο (ΩΡΛ)',
  },
  {
    value: 'dentist',
    label: 'Dentist',
    greekLabel: 'Οδοντίατρος',
  },
  {
    value: 'urologist',
    label: 'Urologist',
    greekLabel: 'Ουρολόγος',
  },
  // {
  //   value: 'reiki-expert',
  //   label: 'Reiki Expert',
  //   greekLabel: 'Ειδικός Reiki',
  // },
  {
    value: 'reproductive-rheumatologist',
    label: 'Reproductive Rheumatologist',
    greekLabel: 'Ρευματολόγος Αναπαραγωγής',
  },
  {
    value: 'lactation-consultant',
    label: 'Lactation Consultant',
    greekLabel: 'Σύμβουλος Θηλασμού',
  },
  // {
  //   value: 'homeopathist-chinese-medicine',
  //   label: 'Homeopathist - Chinese Medicine',
  //   greekLabel: 'Ομοιοπαθητικός - Κινέζικη Ιατρική',
  // },
  {
    value: 'pelvic-floor-myoskeletal',
    label: 'Physiotherapist pelvic floor & myoskeletal',
    greekLabel: 'Φυσικοθεραπευτής πυελικού εδάφους & μυοσκελετικά',
  },
  // {
  //   value: 'genetics-counselor',
  //   label: 'Genetics Counselor',
  //   greekLabel: 'Γενετικός Σύμβουλος',
  // },
  // {
  //   value: 'gender-exploration-therapist',
  //   label: 'Gender Exploration Therapist',
  //   greekLabel: 'Θεραπευτής Γένους',
  // },
  {
    value: 'other',
    label: 'Other',
    greekLabel: 'Άλλο',
  },
];

const ENGLISH_SORTED_PATIENT_OPTIONS = PATIENT_OPTIONS.slice(0).sort((a, b) => {
  const indexA = sortOrder.indexOf(a.label);
  const indexB = sortOrder.indexOf(b.label);

  if (indexA === -1) return 1; // Place unmatched labels at the end.
  if (indexB === -1) return -1;

  return indexA - indexB;
});

export const englishPatientOptions = ENGLISH_SORTED_PATIENT_OPTIONS.map(option => ({
  value: option.value,
  label: option.label,
}));

//These are used in landing patient page
export const GREEK_PATIENT_OPTIONS = [
  // {
  //   value: 'acupuncturist',
  //   label: 'Acupuncturist',
  //   greekLabel: 'Βελονιστής',
  // },
  // {
  //   value: 'genetics-counselor',
  //   label: 'Genetics Counselor',
  //   greekLabel: 'Γενετιστής',
  // },
  {
    value: 'ob-gyn',
    label: 'OB/GYN',
    greekLabel: 'Γυναικολόγος - Μαιευτήρας',
  },
  {
    value: 'general-surgeon',
    label: 'General Surgeon',
    greekLabel: 'Γενικός Χειρουργός',
  },
  {
    value: 'mental-health-advisor',
    label: 'Mental health advisor',
    greekLabel: 'Σύμβουλος ψυχικής υγείας',
  },
  {
    value: 'dermatologist-venereologist',
    label: 'Dermatologist - Venereologist',
    greekLabel: 'Δερματολόγος - Αφροδισιολόγος',
  },
  {
    value: 'nutritionist',
    label: 'Nutritionist',
    greekLabel: 'Διατροφολόγος',
  },
  // {
  //   value: 'reiki-expert',
  //   label: 'Reiki Expert',
  //   greekLabel: 'Ειδικός Reiki',
  // },
  {
    value: 'endocrinologist',
    label: 'Endocrinologist',
    greekLabel: 'Ενδοκρινολόγος',
  },
  {
    value: 'ergotherapist',
    label: 'Ergotherapist',
    greekLabel: 'Εργοθεραπευτής',
  },
  {
    value: 'cardiologist',
    label: 'Cardiologist',
    greekLabel: 'Καρδιολόγος',
  },
  {
    value: 'logotherapist',
    label: 'Logotherapist',
    greekLabel: 'Λογοθεραπευτής',
  },
  {
    value: 'doula-midwife',
    label: 'Doula & Midwife',
    greekLabel: 'Μαία',
  },
  {
    value: 'doula-midwife-male',
    label: 'Male Doula & Midwife',
    greekLabel: 'Μαιευτής',
  },
  {
    value: 'mastologist',
    label: 'Mastologist',
    greekLabel: 'Μαστολόγος',
  },
  {
    value: 'neurologist',
    label: 'Neurologist',
    greekLabel: 'Νευρολόγος',
  },
  {
    value: 'dentist',
    label: 'Dentist',
    greekLabel: 'Οδοντίατρος',
  },
  {
    value: 'urologist',
    label: 'Urologist',
    greekLabel: 'Ουρολόγος',
  },
  {
    value: 'oncologist',
    label: 'Oncologist',
    greekLabel: 'Ογκολόγος',
  },
  // {
  //   value: 'homeopathist-chinese-medicine',
  //   label: 'Homeopathist - Chinese Medicine',
  //   greekLabel: 'Ομοιοπαθητικός - Κινέζικη Ιατρική',
  // },
  {
    value: 'orthopaedic',
    label: 'Orthopaedic',
    greekLabel: 'Ορθοπαιδικός',
  },
  {
    value: 'physiotherapist',
    label: 'Physiotherapist',
    greekLabel: 'Φυσιοθεραπευτής',
  },
  {
    value: 'ent',
    label: 'Ear nose and throat doctor (ENT)',
    greekLabel: 'Ωτορινολαρυγγολόγο (ΩΡΛ)',
  },
  {
    value: 'paediatrician',
    label: 'Paediatrician',
    greekLabel: 'Παιδίατρος',
  },
  {
    value: 'child-psychologist',
    label: 'Child Psychologist',
    greekLabel: 'Παιδοψυχολόγος',
  },
  {
    value: 'childpsychiatrist',
    label: 'Child Psychiatrist',
    greekLabel: 'Παιδοψυχίατρος',
  },
  {
    value: 'drama-therapist',
    label: 'Drama therapist',
    greekLabel: 'Δραματοθεραπεύτρια',
  },
  {
    value: 'plastic-surgeon',
    label: 'Plastic Surgeon',
    greekLabel: 'Πλαστικός χειρουργός',
  },
  {
    value: 'reproductive-rheumatologist',
    label: 'Reproductive Rheumatologist',
    greekLabel: 'Ρευματολόγος',
  },
  {
    value: 'sex-coach',
    label: 'Sex Coach',
    greekLabel: 'Σεξολόγος',
  },
  {
    value: 'lactation-consultant',
    label: 'Lactation Consultant',
    greekLabel: 'Σύμβουλος θηλασμού',
  },
  // {
  //   value: 'career-coach',
  //   label: 'Career Coach',
  //   greekLabel: 'Σύμβουλος καριέρας',
  // },
  {
    value: 'pediatric-surgeon',
    label: 'Pediatric Surgeon',
    greekLabel: 'Χειρουργός Παίδων',
  },
  {
    value: 'health-and-wellness-coach',
    label: 'Health & Wellness Coach',
    greekLabel: 'Σύμβουλος Υγείας και Ευεξίας',
  },
  {
    value: 'social-worker',
    label: 'Social Worker',
    greekLabel: 'Κοινωνικός Λειτουργός',
  },
  {
    value: 'general-practitioner',
    label: 'General Practitioner',
    greekLabel: 'Παθολόγος',
  },
  {
    value: 'head-neck-surgeon',
    label: 'Head & neck surgeon',
    greekLabel: 'Χειρουργός κεφαλής & τραχήλου',
  },
  {
    value: 'adoption-divorce-lawyer',
    label: 'Adoption Advisor',
    greekLabel: 'Δικηγόρος Υιοθεσίας & Διαζυγίου',
  },
  {
    value: 'pelvic-floor-myoskeletal',
    label: 'Physiotherapist pelvic floor & myoskeletal',
    greekLabel: 'Φυσικοθεραπευτής πυελικού εδάφους & μυοσκελετικά',
  },
  // {
  //   value: 'gender-exploration-therapist',
  //   label: 'Gender Exploration Therapist',
  //   greekLabel: 'Χειρουργός Αλλαγής Φύλου',
  // },
  {
    value: 'psychiatrist',
    label: 'Psychiatrist',
    greekLabel: 'Ψυχίατρος',
  },
  {
    value: 'psychologist',
    label: 'Psychologist',
    greekLabel: 'Ψυχολόγος',
  },
  {
    value: 'embryologist',
    label: 'Embryologist',
    greekLabel: 'Εμβρυολόγος',
  },
  {
    value: 'life-coach',
    label: 'Life Coach',
    greekLabel: 'Life coach',
  },
  // {
  //   value: 'sleep-coach',
  //   label: 'Sleep Coach',
  //   greekLabel: 'Sleep coach',
  // },
  {
    value: 'other',
    label: 'Other',
    greekLabel: 'Άλλο',
  },
];

export const greekPatientOptions = GREEK_PATIENT_OPTIONS.map(option => ({
  value: option.value,
  label: option.greekLabel,
}));

export const EXPERTISE_OPTIONS = [
  { value: 'pregnancy', label: 'Pregnancy & Maternity', greekLabel: 'Εγκυμοσύνη & Λοχεία' },
  {
    value: 'birth',
    label: 'Pregnancy, Birth & Postpartum',
    greekLabel: 'Εγκυμοσύνη, Γέννα & Λοχεία',
  },
  {
    value: 'sexual-satisfaction',
    label: 'Sexual Satisfaction',
    greekLabel: 'Σεξουαλική ικανοποίηση',
  },
  { value: 'menopause', label: 'Menopause', greekLabel: 'Εμμηνόπαυση' },
  {
    value: 'endometriosis',
    label: 'Endometriosis & Chronic Illness',
    greekLabel: 'Ενδομητρίωση & Χρόνιες παθήσεις',
  },
  // {
  //   value: 'ivf',
  //   label: 'In Vitro Fertilization & Family',
  //   greekLabel: 'Εξωσωματική Γονιμοποίηση & Οικογένεια',
  // },
  {
    value: 'gynecological-health',
    label: 'Gynecological Health & Abortion',
    greekLabel: 'Γυναικολογική υγεία & Έκτρωση',
  },
  {
    value: 'aesthetic-gynecology',
    label: 'Aesthetic Gynecology & Plastic Surgery',
    greekLabel: 'Αισθητική Γυναικολογία & Πλαστική Χειρουργική',
  },
  { value: 'pediatric-issues', label: 'Pediatric Issues', greekLabel: 'Παιδιατρικά θέματα' },
  {
    value: 'postpartum-depression',
    label: 'Postpartum Depression & Mental Health',
    greekLabel: 'Επιλόχειος κατάθλιψη & Ψυχική υγεία',
  },
  { value: 'other', label: 'Other', greekLabel: 'Άλλο' },
];

export const englishExpertiseOptions = EXPERTISE_OPTIONS.map(option => ({
  value: option.value,
  label: option.label,
}));
export const greekExpertiseOptions = EXPERTISE_OPTIONS.map(option => ({
  value: option.value,
  label: option.greekLabel,
}));

export const englishSpecialtiesOptions = SPECIALTIES_OPTIONS.map(option => ({
  value: option.value,
  label: option.label,
}));

export const greekSpecialtiesOptions = SPECIALTIES_OPTIONS.map(option => ({
  value: option.value,
  label: option.greekLabel,
}));

export const SUB_SPECIALITIES_OPTIONS = [
  {
    value: 'high-risk-pregnancy',
    label: 'High-Risk Pregnancy',
    greekLabel: 'Να διαλέγει όσα θέλει από αυτα:Κύηση υψηλού κινδύνου',
  },
  { value: 'amniocentesis', label: 'Amniocentesis', greekLabel: 'Αμνιοπαρακέντηση' },
  { value: 'genetic-disorders', label: 'Genetic Disorders', greekLabel: 'Γενετικές ασθένειες' },
  { value: 'nuchal-translucency', label: 'Nuchal Translucency', greekLabel: 'Αυχενική διαφάνεια' },
  { value: 'hypnobirth', label: 'Hypnobirth', greekLabel: 'Hypnobirth' },
  { value: 'orgasm', label: 'Orgasm', greekLabel: 'Οργασμός' },
  { value: 'mom-makeover', label: 'Mom Makeover', greekLabel: 'Mom Makeover' },
  { value: 'g-shot', label: 'G-Shot', greekLabel: 'G-shot' },
  { value: 'vaginal-tightening', label: 'Vaginal Tightening', greekLabel: 'Σύσφηξη κόλπου' },
  {
    value: 'vaginal-rejuvenation',
    label: 'Vaginal Rejuvenation',
    greekLabel: 'Αναζωογόνηση κόλπου',
  },
  { value: 'egg-donation', label: 'Egg Donation', greekLabel: 'Δωρεά ωαρίων' },
  { value: 'surrogacy', label: 'Surrogacy', greekLabel: 'Παρένθετη μητρότητα' },
  { value: 'egg-freezing', label: 'Egg Freezing', greekLabel: 'Κατάψυξη Ωαρίων' },
  { value: 'genetic-disorders', label: 'Genetic Disorders', greekLabel: 'Γενετικές ασθένειες' },
  { value: 'abortion', label: 'Abortion', greekLabel: 'Έκτρωση' },
  { value: 'hysterectomy', label: 'Hysterectomy', greekLabel: 'Υστερεκτομή' },
  {
    value: 'adolescent-gynecology',
    label: 'Adolescent Gynecology',
    greekLabel: 'Εφηβική γυναικολογία',
  },
  { value: 'uterine-prolapse', label: 'Uterine Prolapse', greekLabel: 'Πρόπτωση μήτρας' },
  { value: 'urinary-incontinence', label: 'Urinary Incontinence', greekLabel: 'Ακράτεια Ούρων' },
  {
    value: 'warts-stds',
    label: 'Warts & Sexually Transmitted Diseases',
    greekLabel: 'Κονδυλώματα & Σεξουαλικά νοσήματα',
  },
  { value: 'breast-cancer', label: 'Breast Cancer', greekLabel: 'Καρκίνος στήθους' },
  {
    value: 'polycystic-ovary-syndrome',
    label: 'Polycystic Ovary Syndrome',
    greekLabel: 'Πολυκυστικές ωοθήκες',
  },
  { value: 'breast-augmentation', label: 'Breast Augmentation', greekLabel: 'Αυξητική στήθους' },
  { value: 'orgasm', label: 'Orgasm', greekLabel: 'Οργασμός' },
  { value: 'mom-makeover', label: 'Mom Makeover', greekLabel: 'Mom Makeover' },
  { value: 'g-shot', label: 'G-Shot', greekLabel: 'G-shot' },
  { value: 'vaginal-tightening', label: 'Vaginal Tightening', greekLabel: 'Σύσφιγξη κόλπου' },
  {
    value: 'vaginal-rejuvenation',
    label: 'Vaginal Rejuvenation',
    greekLabel: 'Αναζωογόνηση κόλπου',
  },
  { value: 'autism', label: 'Autism', greekLabel: 'Αυτισμός' },
  {
    value: 'behavioral-disorders',
    label: 'Behavioral Disorders',
    greekLabel: 'Συμπεριφοριακές διαταραχές',
  },
  { value: 'adhd', label: 'ADHD', greekLabel: 'ADHD' },
  { value: 'parent-counseling', label: 'Parent Counseling', greekLabel: 'Συμβουλευτική γονέων' },
  {
    value: 'postpartum-depression',
    label: 'Postpartum depression',
    greekLabel: 'Επιλόχειος κατάθλιψη',
  },
  { value: 'panic-attacks', label: 'Panic Attacks', greekLabel: 'Κρίσεις πανικού' },
  { value: 'wellness', label: 'Wellness', greekLabel: 'Ευεξία' },
  {
    value: 'mastectomy-reconstruction',
    label: 'Mastectomy Reconstruction',
    greekLabel: 'Μαστεκτομή & αποκατάσταση',
  },
];

export const DEFAULT_TIME_OPTIONS = [
  { key: '15', label: '15 minutes', greekLabel: '15 λεπτά' },
  { key: '30', label: '30 minutes', greekLabel: '30 λεπτά' },
  { key: '40', label: '40 minutes', greekLabel: '40 λεπτά' },
  { key: '60', label: '60 minutes', greekLabel: '60 λεπτά' },
];

export const DEFAULT_BOOKING_OPTIONS = [
  { value: 'chat', label: 'Chat', greekLabel: 'Συνεδρία με μηνύματα/chat' },
  { value: 'video-call', label: 'Video Call', greekLabel: 'Βιντεοκλήση' },
  { value: 'offline', label: 'Offline', greekLabel: 'εκτός σύνδεσης' },
];

export const DEFAULT_LANG_OPTIONS = [
  { key: '', label: 'Preferred Language', greekLabel: 'Προτιμώμενη γλώσσα', disabled: true },
  { key: 'gr', label: 'Greek', greekLabel: 'Ελληνική' },
  { key: 'en', label: 'English', greekLabel: 'Αγγλικά' },
];

export const GROUPED_EXPERTISE = [
  {
    key: 'doula-midwife',
    label: 'Doula & Midwife',
    greekLabel: 'Μαία',
    options: [
      { value: 'pregnancy', label: 'Pregnancy', greekLabel: 'εγκυμοσύνη' },
      { value: 'lactation', label: 'Lactation', greekLabel: 'θηλασμός' },
      { value: 'ivf', label: 'IVF', greekLabel: 'εξωσωματική γονιμοποίηση IVF' },
      { value: 'hypnobirth', label: 'Hypnobirth', greekLabel: 'hypnobirth' },
      { value: 'water-birth', label: 'Water Birth', greekLabel: 'τοκετός στο νερό' },
      { value: 'home-birth', label: 'Home Birth', greekLabel: 'τοκετός στο σπίτι' },
      {
        value: 'sex-pain',
        label: 'Sex Pain',
        greekLabel: 'πόνος κατά την σεξουαλική επαφή',
      },
      { value: 'stds', label: 'STDs', greekLabel: 'σεξουαλικώς μεταδιδόμενα νοσήματα' },
      { value: 'contraception', label: 'Contraception', greekLabel: 'αντισύλληψη' },
      { value: 'utis', label: 'UTIs', greekLabel: 'ουρολοίμωξη' },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'cancer', label: 'Cancer', greekLabel: 'καρκίνος' },
      { value: 'endometriosis', label: 'Endometriosis', greekLabel: 'ενδομητρίωση' },
      { value: 'vbac', label: 'Endometriosis', greekLabel: 'vbac' },
      {
        value: 'orgasm-sexual-pleasure',
        label: 'Endometriosis',
        greekLabel: 'οργασμός & σεξουαλική ικανοποίηση,',
      },
      { value: 'breech-birth', label: 'Endometriosis', greekLabel: 'ισχιακός τοκετός' },
      { value: 'weaning', label: 'Endometriosis', greekLabel: 'αποθηλασμός' },
      { value: 'sex-education', label: 'Endometriosis', greekLabel: 'σεξουαλική διαπαιδαγώγηση' },
      { value: 'childbirth', label: 'Endometriosis', greekLabel: 'τοκετός' },
    ],
  },
  {
    key: 'doula-midwife-male',
    label: 'Male Doula & Midwife',
    greekLabel: 'Μαιευτής',
    options: [
      { value: 'pregnancy', label: 'Pregnancy', greekLabel: 'εγκυμοσύνη' },
      { value: 'lactation', label: 'Lactation', greekLabel: 'θηλασμός' },
      { value: 'ivf', label: 'IVF', greekLabel: 'εξωσωματική γονιμοποίηση IVF' },
      { value: 'hypnobirth', label: 'Hypnobirth', greekLabel: 'hypnobirth' },
      { value: 'water-birth', label: 'Water Birth', greekLabel: 'τοκετός στο νερό' },
      { value: 'home-birth', label: 'Home Birth', greekLabel: 'τοκετός στο σπίτι' },
      {
        value: 'sex-pain',
        label: 'Sex Pain',
        greekLabel: 'πόνος κατά την σεξουαλική επαφή',
      },
      { value: 'stds', label: 'STDs', greekLabel: 'σεξουαλικώς μεταδιδόμενα νοσήματα' },
      { value: 'contraception', label: 'Contraception', greekLabel: 'αντισύλληψη' },
      { value: 'utis', label: 'UTIs', greekLabel: 'ουρολοίμωξη' },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'cancer', label: 'Cancer', greekLabel: 'καρκίνος' },
      { value: 'endometriosis', label: 'Endometriosis', greekLabel: 'ενδομητρίωση' },
      { value: 'vbac', label: 'Endometriosis', greekLabel: 'vbac' },
      {
        value: 'orgasm-sexual-pleasure',
        label: 'Endometriosis',
        greekLabel: 'οργασμός & σεξουαλική ικανοποίηση,',
      },
      { value: 'breech-birth', label: 'Endometriosis', greekLabel: 'ισχιακός τοκετός' },
      { value: 'weaning', label: 'Endometriosis', greekLabel: 'αποθηλασμός' },
      { value: 'sex-education', label: 'Endometriosis', greekLabel: 'σεξουαλική διαπαιδαγώγηση' },
      { value: 'childbirth', label: 'Endometriosis', greekLabel: 'τοκετός' },
    ],
  },
  {
    key: 'ob-gyn',
    label: 'OB/GYN',
    greekLabel: 'Γυναικολόγος - Μαιευτήρας',
    options: [
      { value: 'ivf', label: 'IVF', greekLabel: 'εξωσωματική γονιμοποίηση IVF' },
      {
        value: 'fertility-treatments',
        label: 'Fertility Treatments',
        greekLabel: 'θεραπείες γονιμότητας',
      },
      {
        value: 'high-risk-pregnancy',
        label: 'High-Risk Pregnancy',
        greekLabel: 'εγκυμοσύνη υψηλού κινδύνου',
      },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'stds', label: 'STDs', greekLabel: 'σεξουαλικώς μεταδιδόμενα νοσήματα' },
      { value: 'hpv', label: 'HPV', greekLabel: 'HPV' },
      { value: 'abortion', label: 'Abortion', greekLabel: 'διακοπή κύησης (άμβλωση)' },
      { value: 'hormone-therapy', label: 'Hormone Therapy', greekLabel: 'ορμονοθεραπεία' },
      { value: 'contraception', label: 'Contraception', greekLabel: 'αντισύλληψη' },
      { value: 'endometriosis', label: 'Endometriosis', greekLabel: 'ενδομητρίωση' },
      { value: 'surrogacy', label: 'Surrogacy', greekLabel: 'παρένθετη μητρότητα' },
      { value: 'urogynaecology', label: 'Urogynaecology', greekLabel: 'ουρογυναικολογία' },
      { value: 'amniocentesis', label: 'Amniocentesis', greekLabel: 'αμνιοπαρακέντηση' },
      { value: 'hysteroscopy', label: 'Hysteroscopy', greekLabel: 'υστεροσκόπηση' },
      { value: 'hysterectomy', label: 'Hysterectomy', greekLabel: 'υστερεκτομή' },
      { value: 'laparoscopy', label: 'Laparoscopy', greekLabel: 'λαπαροσκόπηση' },
      {
        value: 'adolescent-gynaecology',
        label: 'Adolescent Gynaecology',
        greekLabel: 'εφηβική γυναικολογία',
      },
      {
        value: 'uterus-proptosis-treatment',
        label: 'Uterus Proptosis Treatment',
        greekLabel: 'θεραπεία πρόπτωσης μήτρας',
      },
      {
        value: 'vaginal-rejuvenation',
        label: 'Vaginal Rejuvenation',
        greekLabel: 'κολπική αναζωογόνηση',
      },
      {
        value: 'cosmetic-reconstructive-gynaecology',
        label: 'Cosmetic & Reconstructive Gynaecology',
        greekLabel: 'αισθητική και επανορθωτική γυναικολογία',
      },
      { value: 'labiaplasty', label: 'Labiaplasty', greekLabel: 'αιδοιοπλαστική' },
      { value: 'vaginal-lifting', label: 'Vaginal Lifting', greekLabel: 'ανανέωση αιδοίου' },
      {
        value: 'hymen-regeneration',
        label: 'Hymen Regeneration',
        greekLabel: 'ανάπλαση παρθενικού υμένα',
      },
      {
        value: 'g-spot-enhancement',
        label: 'G Spot Enhancement',
        greekLabel: 'διεύρυνση G-SPOT',
      },
      { value: 'vaginoplasty', label: 'Vaginoplasty', greekLabel: 'κολποπλαστική' },
      {
        value: 'bleaching-genital-area',
        label: 'Bleaching Genital Area',
        greekLabel: 'λεύκανση γεννητικής περιοχής',
      },
      { value: 'perineoplasty', label: 'Perineoplasty', greekLabel: 'πλαστική περινέου' },
      {
        value: 'vaginal-tightening',
        label: 'Vaginal Tightening',
        greekLabel: 'σύσφιξη κόλπου',
      },
      { value: 'o-shot', label: 'O-Shot', greekLabel: 'O-Shot' },
      { value: 'vbac', label: 'Endometriosis', greekLabel: 'vbac' },
      {
        value: 'orgasm-sexual-pleasure',
        label: 'Endometriosis',
        greekLabel: 'οργασμός & σεξουαλική ικανοποίηση,',
      },
    ],
  },
  {
    key: 'lactation-consultant',
    label: 'Lactation Consultant',
    greekLabel: 'Σύμβουλος θηλασμού',
    options: [
      {
        value: 'breastfeeding-for-newborns',
        label: 'Breastfeeding for newborns',
        greekLabel: 'θηλασμός για νεογέννητα',
      },
      {
        value: 'breastfeeding-after-6-months',
        label: 'Breastfeeding after 6 months',
        greekLabel: 'θηλασμός μετά τους 6 μήνες',
      },
      { value: 'weaning', label: 'Weaning', greekLabel: 'αποθηλασμός' },
    ],
  },
  // {
  //   key: 'sleep-coach',
  //   label: 'Sleep coach',
  //   greekLabel: 'Sleep coach',
  //   options: [
  //     {
  //       value: 'sleep-training-for-adults',
  //       label: 'Sleep training for adults',
  //       greekLabel: 'εκπαίδευση ύπνου για ενήλικες',
  //     },
  //     {
  //       value: 'sleep-training-for-babies',
  //       label: 'Sleep training for babies',
  //       greekLabel: 'εκπαίδευση ύπνου για μωρά',
  //     },
  //     {
  //       value: 'sleep-training-for-children',
  //       label: 'Sleep training for children',
  //       greekLabel: 'εκπαίδευση ύπνου για παιδιά',
  //     },
  //   ],
  // },
  {
    key: 'pelvic-floor-myoskeletal',
    label: 'Physiotherapist pelvic floor & myoskeletal',
    greekLabel: 'Φυσικοθεραπευτής πυελικού εδάφους & μυοσκελετικά',
    options: [],
  },
  {
    key: 'paediatrician',
    label: 'Paediatrician',
    greekLabel: 'Παιδίατρος',
    options: [
      { value: 'newborn-care', label: 'Newborn care', greekLabel: 'φροντίδα νεογέννητων' },
      {
        value: 'genetic-syndroms',
        label: 'Genetic syndroms',
        greekLabel: 'γενετικά σύνδρομα',
      },
      {
        value: 'prenatal-counseling',
        label: 'Prenatal counseling',
        greekLabel: 'προγεννετική συμβουλευτική',
      },
      {
        value: 'developmental-pediatrician',
        label: 'Developmental pediatrician',
        greekLabel: 'αναπτυξιολόγος παιδίατρος',
      },
      { value: 'autism', label: 'Autism', greekLabel: 'αυτισμός' },
      {
        value: 'charismatic-children',
        label: 'Charismatic children',
        greekLabel: 'χαρισματικά παιδιά',
      },
    ],
  },
  {
    key: 'child-psychologist',
    label: 'Child Psychologist',
    greekLabel: 'Παιδοψυχολόγος',
    options: [
      { value: 'autism', label: 'Autism', greekLabel: 'αυτισμός' },
      { value: 'tantrums', label: 'Tantrums', greekLabel: 'έκρηξη θυμού' },
      {
        value: 'adhd',
        label: 'ADHD',
        greekLabel: 'ελλειμματική προσοχή και υπερκινητικότητα',
      },
      {
        value: 'school-performance',
        label: 'School performance',
        greekLabel: 'σχολική επίδοση',
      },
      { value: 'anxiety', label: 'Anxiety', greekLabel: 'ανησυχία' },
      { value: 'aggressiveness', label: 'Aggressiveness', greekLabel: 'επιθετικότητα' },
      { value: 'addiction', label: 'Addiction', greekLabel: 'εθισμός' },
      { value: 'puberty', label: 'Puberty', greekLabel: 'εφηβεία' },
      { value: 'parenthood', label: 'Parenthood', greekLabel: 'γονεικότητα' },
      {
        value: 'learning-difficulties',
        label: 'Learning difficulties',
        greekLabel: 'μαθησιακές δυσκολίες',
      },
      {
        value: 'problems-at-school',
        label: 'Problems at school',
        greekLabel: 'προβλήματα στο σχολείο',
      },
      {
        value: 'aggression-in-children',
        label: 'Aggression in children',
        greekLabel: 'επιθετικότητα στα παιδιά',
      },
      {
        value: 'putting-limits-in-children',
        label: 'Putting limits in children',
        greekLabel: 'οριοθέτηση στα παιδιά',
      },
      { value: 'toddlers', label: 'Toddlers', greekLabel: 'νήπια' },
      {
        value: 'distraction-hyperactivity',
        label: 'Distraction-Hyperactivity',
        greekLabel: 'διάσπαση προσοχής-υπερκινητικότητα',
      },
      {
        value: 'antisocial-behaviour',
        label: 'Antisocial behaviour',
        greekLabel: 'Αντικοινωνική συμπεριφορά',
      },
      { value: 'anger-outbursts', label: 'Anger outbursts', greekLabel: 'ξεσπάσματα θυμού' },
      { value: 'child-isolation', label: 'Child Isolation', greekLabel: 'απομόνωση' },
      { value: 'school-bullying', label: 'School bullying', greekLabel: 'σχολικός εκφοβισμός' },
      {
        value: 'oppositional-defiant-disorder-odd',
        label: 'Oppositional defiant disorder (ODD)',
        greekLabel: 'εναντιωματική προκλητική διαταραχή (odd)',
      },
      { value: 'adhd', label: 'ADHD', greekLabel: 'δεπυ' },
    ],
  },
  {
    value: 'mental-health-advisor',
    label: 'Mental health advisor',
    greekLabel: 'Σύμβουλος Ψυχικής Υγείας',
    options: [
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'stress', label: 'Stress', greekLabel: 'στρες' },
      { value: 'anxiety', label: 'Anxiety', greekLabel: 'άγχος' },
      { value: 'parenting', label: 'Parenting', greekLabel: 'ανατροφή των παιδιών' },
      { value: 'wellness', label: 'Wellness', greekLabel: 'ευεξία' },
      { value: 'mindfulness', label: 'Mindfulness', greekLabel: 'ενσυνειδητότητα' },
      { value: 'cbt', label: 'CBT', greekLabel: 'γνωστική συμπεριφοριστική θεραπεία' },
      {
        value: 'effective-thinking',
        label: 'Effective thinking',
        greekLabel: 'αποτελεσματική σκέψη',
      },
      { value: 'meditation', label: 'Meditation', greekLabel: 'διαλογισμός' },
      { value: 'divorce', label: 'Divorce, ', greekLabel: 'διαζύγιο' },
      {
        value: 'postpatrum-depression',
        label: 'Postpatrum depression',
        greekLabel: 'επιλόχειος κατάθλιψη,',
      },
      { value: 'adhd', label: 'ADHD', greekLabel: 'δεπυ' },
      { value: 'bi-polar', label: 'Bi-polar', greekLabel: 'διπολική διαταραχή' },
      { value: 'art-therapy', label: 'Art Therapy', greekLabel: 'θεραπεία μέσω της τέχνης' },
      { value: 'sex-therapy', label: 'Sex therapy', greekLabel: 'σεξουαλική θεραπεία' },
      {
        value: 'brief-motivational-interview',
        label: 'Brief Motivational Interview',
        greekLabel: 'Βραχύχρονη Κινητοποιητική συνέντευξη',
      },
      {
        value: 'systemic-constellation-techniques',
        label: 'Systemic Constellation Techniques',
        greekLabel: 'Τεχνικές Συστημικής Αναπαράστασης',
      },
      {
        value: 'infertility-support-groups',
        label: 'Infertility Support Groups',
        greekLabel: 'Ομάδες Υποστήριξης Υπογονιμότητας',
      },
      {
        value: 'individual-psychotherapy',
        label: 'Individual psychotherapy',
        greekLabel: 'Ατομική ψυχοθεραπεία',
      },
      {
        value: 'couples-counseling-infertility',
        label: 'Couples counseling on infertility issues',
        greekLabel: 'Συμβουλευτική ζεύγους σε θέματα υπογονιμότητας',
      },
      {
        value: 'couples-counseling-divorce',
        label: 'Couples counseling on divorce and transitions',
        greekLabel: 'Συμβουλευτική ζεύγους σε θέματα διαζυγίου και μεταβάσεων',
      },
      {
        value: 'management-interpersonal-relationships',
        label: 'Management of interpersonal relationships',
        greekLabel: 'Διαχείριση διαπροσωπικών σχέσεων',
      },
      { value: 'addiction', label: 'Addiction', greekLabel: 'εθισμός' },
      {
        value: 'teenager-consulting',
        label: 'Teenager consulting',
        greekLabel: 'προβλήματα σε εφήβους',
      },
      {
        value: 'parenting-consulting',
        label: 'Parenting consulting',
        greekLabel: 'συμβουλευτική γονέων',
      },
      {
        value: 'sexual-disorders',
        label: 'Sexual disorders',
        greekLabel: 'ψυχοσεξουαλικές διαταραχές',
      },
      { value: 'endometriosis', label: 'Endometriosis', greekLabel: 'ενδομητρίωση' },
      { value: 'panic-disorder', label: 'Panic disorder', greekLabel: 'κρίσεις πανικού' },
      {
        value: 'orgasm-sexual-pleasure',
        label: 'Orgasm & sexual pleasure',
        greekLabel: 'οργασμός και σεξουαλική ευχαρίστηση',
      },
      { value: 'couples-therapy, ', label: 'Couples therapy', greekLabel: 'σύμβουλος ζευγαριών' },
      { value: 'grief', label: 'Grief', greekLabel: 'θυμός' },
      { value: 'gender-dysphoria', label: 'Gender Dysphoria', greekLabel: 'δυσφορία φύλου' },
      {
        value: 'pain-during-intercourse',
        label: 'Pain during intercourse',
        greekLabel: 'πόνος κατά την σεξουαλική επαφή',
      },
      {
        value: 'lack-of-sexual-desire',
        label: 'Lack of sexual desire',
        greekLabel: 'έλλειψη ερωτικής επιθυμίας',
      },
      { value: 'puberty', label: 'Puberty', greekLabel: 'εφηβεία' },
      { value: 'parenthood', label: 'Parenthood', greekLabel: 'γονεικότητα' },
      {
        value: 'learning-difficulties',
        label: 'Learning difficulties',
        greekLabel: 'μαθησιακές δυσκολίες',
      },
      {
        value: 'problems-at-school',
        label: 'Problems at school',
        greekLabel: 'προβλήματα στο σχολείο',
      },
      {
        value: 'aggression-in-children',
        label: 'Aggression in children',
        greekLabel: 'επιθετικότητα στα παιδιά',
      },
      {
        value: 'putting-limits-in-children',
        label: 'Putting limits in children',
        greekLabel: 'οριοθέτηση στα παιδιά',
      },
      { value: 'toddlers', label: 'Toddlers', greekLabel: 'νήπια' },
      {
        value: 'distraction-hyperactivity',
        label: 'Distraction-Hyperactivity',
        greekLabel: 'διάσπαση προσοχής-υπερκινητικότητα',
      },
      {
        value: 'antisocial-behaviour',
        label: 'Antisocial behaviour',
        greekLabel: 'αντικοινωνική συμπεριφορά',
      },
      { value: 'anger-outbursts', label: 'Anger outbursts', greekLabel: 'ξεσπάσματα θυμού' },
      { value: 'child-isolation', label: 'Child Isolation', greekLabel: 'απομόνωση' },
      { value: 'school-bullying', label: 'School bullying', greekLabel: 'σχολικός εκφοβισμός' },
      {
        value: 'oppositional-defiant-disorder-odd',
        label: 'Oppositional defiant disorder (ODD)',
        greekLabel: 'εναντιωματική προκλητική διαταραχή ODD',
      },
      { value: 'self-care', label: 'Self-care', greekLabel: 'Αυτοφροντίδα' },
      { value: 'work-life-balance', label: 'Work-Life Balance', greekLabel: 'Work-Life Balance' },
      { value: 'holistic-health', label: 'Holistic Health', greekLabel: 'Ολιστική Υγεία' },
      { value: 'change-of-habits', label: 'Change of habits', greekLabel: 'Αλλαγή συνηθειών' },
      {
        value: 'detoxification',
        label: 'Detoxification (clean living)',
        greekLabel: 'Αποτοξίνωση (clean living)',
      },
    ],
  },
  {
    key: 'psychologist',
    label: 'Psychologist',
    greekLabel: 'Ψυχολόγος',
    options: [
      { value: 'divorce', label: 'Divorce', greekLabel: 'διαζύγιο' },
      { value: 'parenting', label: 'Parenting', greekLabel: 'ανατροφή των παιδιών' },
      {
        value: 'effective-thinking',
        label: 'Effective Thinking',
        greekLabel: 'αποτελεσματική σκέψη',
      },
      { value: 'mindfulness', label: 'Mindfulness', greekLabel: 'ενσυνειδητότητα' },
      { value: 'anxiety', label: 'Anxiety', greekLabel: 'ανησυχία' },
      { value: 'depression', label: 'Depression', greekLabel: 'κατάθλιψη' },
      {
        value: 'postpartum-depression',
        label: 'Postpartum depression',
        greekLabel: 'επιλόχειος κατάθλιψη',
      },
      { value: 'mourning-loss', label: 'Mourning/loss', greekLabel: 'πένθος' },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'stress', label: 'Stress', greekLabel: 'άγχος' },
      {
        value: 'adhd',
        label: 'ADHD',
        greekLabel: 'ελλειμματική προσοχή και υπερκινητικότητα',
      },
      { value: 'bi-polar', label: 'Bi-polar', greekLabel: 'διπολική διαταραχή' },
      { value: 'sex-therapy', label: 'Sex therapy', greekLabel: 'sex therapy' },
      {
        value: 'couples-therapy',
        label: 'Couples therapy',
        greekLabel: 'σύμβουλος ζευγαριών',
      },
      { value: 'cbt', label: 'CBT', greekLabel: 'γνωστική συμπεριφοριστική θεραπεία' },
      { value: 'addiction', label: 'Addiction', greekLabel: 'εθισμός' },
      {
        value: 'teenager-consulting',
        label: 'Teenager consulting',
        greekLabel: 'προβλήματα σε εφήβους',
      },
      {
        value: 'parenting-consulting',
        label: 'Parenting consulting',
        greekLabel: 'συμβουλευτική γονέων',
      },
      {
        value: 'sexual-disorders',
        label: 'Sexual disorders',
        greekLabel: 'ψυχοσεξουαλικές διαταραχές',
      },
      { value: 'endometriosis', label: 'Endometriosis', greekLabel: 'ενδομητρίωση' },
      { value: 'panic-disorder', label: 'Panic disorder', greekLabel: 'κρίσεις πανικού' },
      {
        value: 'orgasm-sexual-pleasure',
        label: 'Endometriosis',
        greekLabel: 'οργασμός & σεξουαλική ικανοποίηση,',
      },
      { value: 'couples-therapy', label: 'Couples therapy', greekLabel: 'θεραπεία ζευγαριών' },
      { value: 'grief', label: 'grief', greekLabel: 'πένθος' },
      { value: 'gender-dysphoria', label: 'Gender Dysphoria', greekLabel: 'δυσφορία φύλου' },
      {
        value: 'pain-during-intercourse',
        label: 'Pain during intercourse',
        greekLabel: 'πόνος κατά την σεξουαλική επαφή',
      },
      {
        value: 'lack-of-sexual-desire',
        label: 'Lack of sexual desire',
        greekLabel: 'έλλειψη ερωτικής επιθυμίας',
      },
      { value: 'puberty', label: 'Puberty', greekLabel: 'εφηβεία' },
      { value: 'parenthood', label: 'Parenthood', greekLabel: 'γονεικότητα' },
      {
        value: 'learning-difficulties',
        label: 'Learning difficulties',
        greekLabel: 'μαθησιακές δυσκολίες',
      },
      {
        value: 'problems-at-school',
        label: 'Problems at school',
        greekLabel: 'προβλήματα στο σχολείο',
      },
      {
        value: 'aggression-in-children',
        label: 'Aggression in children',
        greekLabel: 'επιθετικότητα στα παιδιά',
      },
      {
        value: 'putting-limits-in-children',
        label: 'Putting limits in children',
        greekLabel: 'οριοθέτηση στα παιδιά',
      },
      { value: 'toddlers', label: 'Toddlers', greekLabel: 'νήπια' },
      {
        value: 'distraction-hyperactivity',
        label: 'Distraction-Hyperactivity',
        greekLabel: 'διάσπαση προσοχής-υπερκινητικότητα',
      },
      {
        value: 'antisocial-behaviour',
        label: 'Antisocial behaviour',
        greekLabel: 'αντικοινωνική συμπεριφορά',
      },
      { value: 'anger-outbursts', label: 'Anger outbursts', greekLabel: 'ξεσπάσματα θυμού' },
      { value: 'child-isolation', label: 'Child Isolation', greekLabel: 'απομόνωση' },
      { value: 'school-bullying', label: 'School bullying', greekLabel: 'σχολικός εκφοβισμός' },
      {
        value: 'oppositional-defiant-disorder-odd',
        label: 'Oppositional defiant disorder (ODD)',
        greekLabel: 'εναντιωματική προκλητική διαταραχή ODD',
      },
      { value: 'adhd', label: 'ADHD', greekLabel: 'δεπυ' },
      { value: 'self-care', label: 'Self-care', greekLabel: 'Αυτοφροντίδα' },
      { value: 'work-life-balance', label: 'Work-Life Balance', greekLabel: 'Work-Life Balance' },
      { value: 'holistic-health', label: 'Holistic Health', greekLabel: 'Ολιστική Υγεία' },
      { value: 'change-of-habits', label: 'Change of habits', greekLabel: 'Αλλαγή συνηθειών' },
      {
        value: 'detoxification',
        label: 'Detoxification (clean living)',
        greekLabel: 'Αποτοξίνωση (clean living)',
      },
    ],
  },
  {
    key: 'psychiatrist',
    label: 'Psychiatrist',
    greekLabel: 'Ψυχίατρος',
    options: [
      {
        value: 'postpartum-depression',
        label: 'Postpartum depression',
        greekLabel: 'επιλόχειος κατάθλιψη',
      },
      { value: 'parenting', label: 'Parenting', greekLabel: 'ανατροφή των παιδιών' },
      {
        value: 'effective-thinking',
        label: 'Effective Thinking',
        greekLabel: 'αποτελεσματική σκέψη',
      },
      { value: 'mindfulness', label: 'Mindfulness', greekLabel: 'ενσυνειδητότητα' },
      { value: 'anxiety', label: 'Anxiety', greekLabel: 'ανησυχία' },
      { value: 'depression', label: 'Depression', greekLabel: 'κατάθλιψη' },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'stress', label: 'Stress', greekLabel: 'άγχος' },
      {
        value: 'adhd',
        label: 'ADHD',
        greekLabel: 'διαταραχή ελλειμματικής προσοχής και υπερκινητικότητας (ADHD)',
      },
      { value: 'bi-polar', label: 'Bi-polar', greekLabel: 'διπολική διαταραχή' },
      {
        value: 'divorce-relationships',
        label: 'Divorce & relationships',
        greekLabel: 'διαζύγιο και σχέσεις',
      },
      {
        value: 'sexual-disorders',
        label: 'Sexual disorders',
        greekLabel: 'ψυχοσεξουαλικές διαταραχές',
      },
      { value: 'endometriosis', label: 'Endometriosis', greekLabel: 'ενδομητρίωση' },
      {
        value: 'orgasm-sexual-pleasure',
        label: 'Endometriosis',
        greekLabel: 'οργασμός & σεξουαλική ικανοποίηση,',
      },
      { value: 'couples-therapy', label: 'Couples therapy', greekLabel: 'θεραπεία ζευγαριών' },
      { value: 'grief', label: 'grief', greekLabel: 'πένθος' },
      {
        value: 'pain-during-intercourse',
        label: 'Pain during intercourse',
        greekLabel: 'πόνος κατά την σεξουαλική επαφή',
      },
      {
        value: 'lack-of-sexual-desire',
        label: 'Lack of sexual desire',
        greekLabel: 'έλλειψη ερωτικής επιθυμίας',
      },
      { value: 'puberty', label: 'Puberty', greekLabel: 'εφηβεία' },
      { value: 'parenthood', label: 'Parenthood', greekLabel: 'γονεικότητα' },
      {
        value: 'learning-difficulties',
        label: 'Learning difficulties',
        greekLabel: 'μαθησιακές δυσκολίες',
      },
      {
        value: 'problems-at-school',
        label: 'Problems at school',
        greekLabel: 'προβλήματα στο σχολείο',
      },
      {
        value: 'aggression-in-children',
        label: 'Aggression in children',
        greekLabel: 'επιθετικότητα στα παιδιά',
      },
      {
        value: 'putting-limits-in-children',
        label: 'Putting limits in children',
        greekLabel: 'οριοθέτηση στα παιδιά',
      },
      { value: 'toddlers', label: 'Toddlers', greekLabel: 'νήπια' },
      {
        value: 'distraction-hyperactivity',
        label: 'Distraction-Hyperactivity',
        greekLabel: 'διάσπαση προσοχής-υπερκινητικότητα',
      },
      {
        value: 'antisocial-behaviour',
        label: 'Antisocial behaviour',
        greekLabel: 'αντικοινωνική συμπεριφορά',
      },
      { value: 'anger-outbursts', label: 'Anger outbursts', greekLabel: 'ξεσπάσματα θυμού' },
      { value: 'child-isolation', label: 'Child Isolation', greekLabel: 'απομόνωση' },
      { value: 'school-bullying', label: 'School bullying', greekLabel: 'σχολικός εκφοβισμός' },
      {
        value: 'oppositional-defiant-disorder-odd',
        label: 'Oppositional defiant disorder (ODD)',
        greekLabel: 'εναντιωματική προκλητική διαταραχή ODD',
      },
    ],
  },
  {
    key: 'childpsychiatrist',
    label: 'Child Psychiatrist',
    greekLabel: 'Παιδοψυχίατρος',
    options: [
      { value: 'autism', label: 'Autism', greekLabel: 'αυτισμός' },
      { value: 'tantrums', label: 'Tantrums', greekLabel: 'έκρηξη θυμού' },
      {
        value: 'adhd',
        label: 'ADHD',
        greekLabel: 'ελλειμματική προσοχή και υπερκινητικότητα',
      },
      {
        value: 'school-performance',
        label: 'School performance',
        greekLabel: 'σχολική επίδοση',
      },
      { value: 'anxiety', label: 'Anxiety', greekLabel: 'ανησυχία' },
      { value: 'aggressiveness', label: 'Aggressiveness', greekLabel: 'επιθετικότητα' },
      { value: 'addiction', label: 'Addiction', greekLabel: 'εθισμός' },
      { value: 'puberty', label: 'Puberty', greekLabel: 'εφηβεία' },
      { value: 'parenthood', label: 'Parenthood', greekLabel: 'γονεικότητα' },
      {
        value: 'learning-difficulties',
        label: 'Learning difficulties',
        greekLabel: 'μαθησιακές δυσκολίες',
      },
      {
        value: 'problems-at-school',
        label: 'Problems at school',
        greekLabel: 'προβλήματα στο σχολείο',
      },
      {
        value: 'aggression-in-children',
        label: 'Aggression in children',
        greekLabel: 'επιθετικότητα στα παιδιά',
      },
      {
        value: 'putting-limits-in-children',
        label: 'Putting limits in children',
        greekLabel: 'οριοθέτηση στα παιδιά',
      },
      { value: 'toddlers', label: 'Toddlers', greekLabel: 'νήπια' },
      {
        value: 'distraction-hyperactivity',
        label: 'Distraction-Hyperactivity',
        greekLabel: 'διάσπαση προσοχής-υπερκινητικότητα',
      },
      {
        value: 'antisocial-behaviour',
        label: 'Antisocial behaviour',
        greekLabel: 'αντικοινωνική συμπεριφορά',
      },
      { value: 'anger-outbursts', label: 'Anger outbursts', greekLabel: 'ξεσπάσματα θυμού' },
      { value: 'child-isolation', label: 'Child Isolation', greekLabel: 'απομόνωση' },
      { value: 'school-bullying', label: 'School bullying', greekLabel: 'σχολικός εκφοβισμός' },
      {
        value: 'oppositional-defiant-disorder-odd',
        label: 'Oppositional defiant disorder (ODD)',
        greekLabel: 'εναντιωματική προκλητική διαταραχή ODD',
      },
      { value: 'adhd', label: 'ADHD', greekLabel: 'δεπυ' },
    ],
  },
  {
    key: 'endocrinologist',
    label: 'Endocrinologist',
    greekLabel: 'Ενδοκρινολόγος',
    options: [
      { value: 'fertility', label: 'Fertility', greekLabel: 'γονιμότητα' },
      { value: 'theroid', label: 'Theroid', greekLabel: 'θυρεοειδής' },
      { value: 'diabetes', label: 'Diabetes', greekLabel: 'διαβήτης' },
      { value: 'hormones', label: 'Hormones', greekLabel: 'ορμόνες' },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      {
        value: 'diabetes-mellitus',
        label: 'Diabetes Mellitus',
        greekLabel: 'σακχαρώδης διαβήτης',
      },
    ],
  },
  {
    key: 'urologist',
    label: 'Urologist',
    greekLabel: 'Ουρολόγος',
    options: [
      { value: 'male-fertility', label: 'Male fertility', greekLabel: 'Ανδρική γονιμότητα' },
      { value: 'prostate-cancer', label: 'Prostate cancer', greekLabel: 'Καρκίνος του προστάτη' },
      {
        value: 'erectile-dysfunction',
        label: 'Erectile dysfunction',
        greekLabel: 'Στυτική δυσλειτουργία',
      },
      { value: 'kidney-stones', label: 'Kidney stones', greekLabel: 'Πέτρες στα νεφρά' },
      {
        value: 'congenital-urinary-tract-issues',
        label: 'Congenital urinary tract issues',
        greekLabel: 'Συγγενή προβλήματα του ουροποιητικού συστήματος',
      },
      { value: 'urologist', label: 'Urologist', greekLabel: 'Ουρολόγος' },
    ],
  },
  {
    key: 'adoption-divorce-lawyer',
    label: 'Adoption & Divorce Lawyer',
    greekLabel: 'Δικηγόρος Υιοθεσίας & Διαζυγίου',
    options: [
      { value: 'divorce', label: 'Divorce', greekLabel: 'διαζύγιο' },
      { value: 'adoption', label: 'Adoption', greekLabel: 'υιοθεσία' },
      { value: 'surrogacy', label: 'Surrogacy', greekLabel: 'δωρεά ωαρίων' },
      { value: 'egg-donation', label: 'Egg donation', greekLabel: 'παρένθετη μητρότητα' },
    ],
  },
  {
    key: 'plastic-surgeon',
    label: 'Plastic Surgeon',
    greekLabel: 'Πλαστικός χειρουργός',
    options: [
      { value: 'abdominoplasty', label: 'Abdominoplasty', greekLabel: 'κοιλιοπλαστική' },
      { value: 'blepharoplasty', label: 'Blepharoplasty', greekLabel: 'βλεφαροπλαστική' },
      { value: 'nose-surgery', label: 'Nose surgery', greekLabel: 'ρινοπλαστικη' },
      { value: 'ears-surgery', label: 'Ears surgery', greekLabel: 'και ωτοπλαστικη' },
      { value: 'botox', label: 'Botox', greekLabel: 'botox' },
      {
        value: 'breast-plastic-surgery',
        label: 'Breast Plastic Surgery',
        greekLabel: 'πλαστική χειρουργική μαστού',
      },
      {
        value: 'buttock-augmentation',
        label: 'Buttock Augmentation',
        greekLabel: 'αυξητική γλουτών',
      },
      {
        value: 'skin-cancer',
        label: 'Skin cancer',
        greekLabel: 'καρκίνος του δέρματος',
      },
      {
        value: 'melanoma',
        label: 'Melanoma',
        greekLabel: 'μελάνωμα',
      },
      {
        value: 'vaginoplasty',
        label: 'Vaginoplasty',
        greekLabel: 'αιδοιοπλαστική',
      },
      {
        value: 'lmphedema-lipedema-treatment',
        label: 'Lymphedema & Lipedema treatment',
        greekLabel: 'Αντιμετώπιση Λεμφοιδήματος και Λιποιδήματος',
      },
      {
        value: 'hyaluronic-acid-fillers',
        label: 'Hyaluronic Acid/Fillers',
        greekLabel: 'υαλουρονικό οξύ / fillers',
      },
      { value: 'lipocuction', label: 'Lipocuction', greekLabel: 'λιποαναρρόφηση' },
      { value: 'rhinoplasty', label: 'Rhinoplasty', greekLabel: 'ρινοπλαστική' },
      { value: 'lifting', label: 'Lifting', greekLabel: 'lifting' },
      { value: 'vaginoplasty', label: 'Vaginoplasty', greekLabel: 'κολποπλαστική' },
      {
        value: 'gender-affirmation-surgery',
        label: 'Gender Affirmation Surgery',
        greekLabel: 'χειρουργική αλλαγής φύλου',
      },
      { value: 'cellulite', label: 'Cellulite', greekLabel: 'κυτταρίτιδα' },
    ],
  },
  {
    key: 'sex-coach',
    label: 'Sex coach',
    greekLabel: 'Σεξολόγος',
    options: [
      { value: 'mourning-loss', label: 'Mourning/loss', greekLabel: 'πένθος' },
      { value: 'gender-dusphoria', label: 'Gender dusphoria', greekLabel: 'δυσφορία φύλου' },
      {
        value: 'orgasm-sexual-pleasure',
        label: 'Endometriosis',
        greekLabel: 'οργασμός & σεξουαλική ικανοποίηση,',
      },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'divorce', label: 'Divorce', greekLabel: 'διαζύγιο' },
      { value: 'postpatrum-sex', label: 'Postpatrum sex', greekLabel: 'σεξ μετά τον τοκετό' },
      { value: 'stress', label: 'Stress', greekLabel: 'άγχος' },
      { value: 'sex-therapy', label: 'Sex therapy', greekLabel: 'σεξουαλική θεραπεία' },
      { value: 'couples-therapy', label: 'Couples therapy', greekLabel: 'θεραπεία ζευγαριών' },
      {
        value: 'teenager-consulting',
        label: 'Teenager consulting',
        greekLabel: 'συμβουλευτική εφήβων',
      },
      {
        value: 'parenting-consulting',
        label: 'Parenting consulting',
        greekLabel: 'συμβουλευτική γονέων',
      },
      {
        value: 'sexual-disorders',
        label: 'Sexual disorders',
        greekLabel: 'σεξουαλικές διαταραχές',
      },
    ],
  },
  {
    key: 'dermatologist-venereologist',
    label: 'Dermatologist - Venereologist',
    greekLabel: 'Δερματολόγος - Αφροδισιολόγος',
    options: [
      { value: 'botox', label: 'Botox', greekLabel: 'μπότοξ & ενέσιμα' },
      {
        value: 'vaginal-dryness',
        label: 'Vaginal Dryness',
        greekLabel: 'ξηρότητα του κόλπου',
      },
      { value: 'stds', label: 'STDs', greekLabel: 'σεξουαλικώς μεταδιδόμενα νοσήματα' },
      { value: 'antiaging', label: 'Antiaging', greekLabel: 'αντιγήρανση' },
      { value: 'acne', label: 'Acne', greekLabel: 'ακμή' },
      { value: 'stretch-marks', label: 'Stretch Marks', greekLabel: 'ραγάδες' },
      { value: 'tattoo-removal', label: 'Tattoo Removal', greekLabel: 'αφαίρεση τατουάζ' },
      { value: 'hair-removal', label: 'Hair Removal', greekLabel: 'αποτρίχωση' },
      { value: 'dark-circles', label: 'Dark Circles', greekLabel: 'μαύροι κύκλοι' },
      { value: 'mesotherapy', label: 'Mesotherapy', greekLabel: 'μεσοθεραπείες' },
      { value: 'autoimmune-disease', label: 'Autoimmune Disease', greekLabel: 'αυτοάνοσα' },
      { value: 'eczema', label: 'Eczema', greekLabel: 'έκζεμα' },
      { value: 'psoriasis', label: 'Psoriasis', greekLabel: 'ψωρίαση' },
    ],
  },
  // {
  //   key: 'gender-exploration-therapist',
  //   label: 'Gender exploration therapist',
  //   greekLabel: 'Χειρουργός Αλλαγής Φύλου',
  //   options: [],
  // },
  {
    key: 'logotherapist',
    label: 'Logotherapist',
    greekLabel: 'Λογοθεραπευτής',
    options: [
      { value: 'speech-therapy', label: 'Speech Therapy', greekLabel: 'λογοθεραπεία' },
      {
        value: 'voice-disorders',
        label: 'Voice Disorders',
        greekLabel: 'φωνητικές διαταραχές',
      },
      { value: 'dyslexia', label: 'Dyslexia', greekLabel: 'δυσλεξία' },
      { value: 'dyscalculia', label: 'Dyscalculia', greekLabel: 'δυσαριθμησία' },
      {
        value: 'phonological-disorders',
        label: 'Phonological Disorders',
        greekLabel: 'φωνολογικές διαταραχές',
      },
      {
        value: 'speech-and-language-delay',
        label: 'Speech and Language Delay',
        greekLabel: 'καθυστέρηση ομιλίας και λόγου',
      },
    ],
  },
  {
    key: 'life-coach',
    label: 'Life coach',
    greekLabel: 'Life coach',
    options: [
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'stress', label: 'Stress', greekLabel: 'στρες' },
      { value: 'anxiety', label: 'Anxiety', greekLabel: 'άγχος' },
      { value: 'parenting', label: 'Parenting', greekLabel: 'ανατροφή των παιδιών' },
      { value: 'wellness', label: 'Wellness', greekLabel: 'ευεξία' },
      { value: 'mindfulness', label: 'Mindfulness', greekLabel: 'ενσυνειδητότητα' },
      { value: 'cbt', label: 'CBT', greekLabel: 'γνωστική συμπεριφορική θεραπεία' },
      {
        value: 'effective-thinking',
        label: 'Effective Thinking',
        greekLabel: 'αποτελεσματική σκέψη',
      },
      { value: 'meditation', label: 'Meditation', greekLabel: 'διαλογισμός' },
      { value: 'puberty', label: 'Puberty', greekLabel: 'εφηβεία' },
      { value: 'parenthood', label: 'Parenthood', greekLabel: 'γονεικότητα' },
      {
        value: 'learning-difficulties',
        label: 'Learning difficulties',
        greekLabel: 'μαθησιακές δυσκολίες',
      },
      {
        value: 'problems-at-school',
        label: 'Problems at school',
        greekLabel: 'προβλήματα στο σχολείο',
      },
      {
        value: 'aggression-in-children',
        label: 'Aggression in children',
        greekLabel: 'επιθετικότητα στα παιδιά',
      },
      {
        value: 'putting-limits-in-children',
        label: 'Putting limits in children',
        greekLabel: 'οριοθέτηση στα παιδιά',
      },
      { value: 'toddlers', label: 'Toddlers', greekLabel: 'νήπια' },
      {
        value: 'distraction-hyperactivity',
        label: 'Distraction-Hyperactivity',
        greekLabel: 'διάσπαση προσοχής-υπερκινητικότητα',
      },
      {
        value: 'antisocial-behaviour',
        label: 'Antisocial behaviour',
        greekLabel: 'αντικοινωνική συμπεριφορά',
      },
      { value: 'anger-outbursts', label: 'Anger outbursts', greekLabel: 'ξεσπάσματα θυμού' },
      { value: 'child-isolation', label: 'Child Isolation', greekLabel: 'απομόνωση' },
      { value: 'school-bullying', label: 'School bullying', greekLabel: 'σχολικός εκφοβισμός' },
      {
        value: 'oppositional-defiant-disorder-odd',
        label: 'Oppositional defiant disorder (ODD)',
        greekLabel: 'εναντιωματική προκλητική διαταραχή ODD',
      },
      { value: 'adhd', label: 'ADHD', greekLabel: 'δεπυ' },
      { value: 'self-care', label: 'Self-care', greekLabel: 'Αυτοφροντίδα' },
      { value: 'work-life-balance', label: 'Work-Life Balance', greekLabel: 'Work-Life Balance' },
      { value: 'holistic-health', label: 'Holistic Health', greekLabel: 'Ολιστική Υγεία' },
      { value: 'change-of-habits', label: 'Change of habits', greekLabel: 'Αλλαγή συνηθειών' },
      {
        value: 'detoxification',
        label: 'Detoxification (clean living)',
        greekLabel: 'Αποτοξίνωση (clean living)',
      },
      {
        value: 'relationship-coaching',
        label: 'Relationship Coaching',
        greekLabel: 'Προπόνηση σχέσεων',
      },
    ],
  },
  {
    key: 'drama-therapist',
    label: 'Drama therapist',
    greekLabel: 'Δραματοθεραπεύτρια',
    options: [
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'stress', label: 'Stress', greekLabel: 'στρες' },
      { value: 'anxiety', label: 'Anxiety', greekLabel: 'άγχος' },
      { value: 'parenting', label: 'Parenting', greekLabel: 'ανατροφή των παιδιών' },
      { value: 'wellness', label: 'Wellness', greekLabel: 'ευεξία' },
      { value: 'mindfulness', label: 'Mindfulness', greekLabel: 'ενσυνειδητότητα' },
      { value: 'cbt', label: 'CBT', greekLabel: 'γνωστική συμπεριφορική θεραπεία' },
      {
        value: 'effective-thinking',
        label: 'Effective Thinking',
        greekLabel: 'αποτελεσματική σκέψη',
      },
      { value: 'meditation', label: 'Meditation', greekLabel: 'διαλογισμός' },
    ],
  },
  {
    key: 'embryologist',
    label: 'Embryologist',
    greekLabel: 'Εμβρυολόγος',
    options: [],
  },
  {
    key: 'mastologist',
    label: 'Mastologist',
    greekLabel: 'Μαστολόγος',
    options: [],
  },
  {
    key: 'oncologist',
    label: 'Oncologist',
    greekLabel: 'Ογκολόγος',
    options: [
      {
        value: 'gynecological-cancer',
        label: 'Gynecological Cancer',
        greekLabel: 'γυναικολογικός καρκίνος',
      },
      { value: 'breast-cancer', label: 'Breast Cancer', greekLabel: 'καρκίνος μαστού' },
      {
        value: 'digestive-cancer',
        label: 'Digestive Cancer',
        greekLabel: 'καρκίνος πεπτικού',
      },
      { value: 'lung-cancer', label: 'Lung Cancer', greekLabel: 'καρκίνος πνεύμονος' },
      {
        value: 'head-neck-cancer',
        label: 'Head/Neck Cancer',
        greekLabel: 'καρκίνος κεφαλής/τραχήλου',
      },
      {
        value: 'gastrointestinal-cancer',
        label: 'Gastrointestinal Cancer',
        greekLabel: 'καρκίνος γαστρεντερολογικού συστήματος',
      },
      {
        value: 'metastatic-cancer-oligometastatic-disease',
        label: 'Metastatic Cancer - Oligometastatic Disease',
        greekLabel: 'μεταστατικός καρκίνο - ολιγομεταστατική νόσος',
      },
      {
        value: 'skin-carcinomas',
        label: 'Skin Carcinomas',
        greekLabel: 'δερματικά καρκινώματα',
      },
      { value: 'sarcomas', label: 'Sarcomas', greekLabel: 'σαρκώματα' },
      { value: 'lymphomas', label: 'Lymphomas', greekLabel: 'λεμφώματα' },
      {
        value: 'benign-connective-tissue-diseases',
        label: 'Benign Connective Tissue Diseases',
        greekLabel: 'καλοήθεις παθήσεις συνδετικού ιστού',
      },
    ],
  },
  {
    key: 'nutritionist',
    label: 'Nutritionist',
    greekLabel: 'Διατροφολόγος',
    options: [
      { value: 'postpartum', label: 'Postpartum', greekLabel: 'λοχεία' },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
      { value: 'pcos', label: 'PCOS', greekLabel: 'σύνδρομο πολυκυστικών ωοθηκών' },
      { value: 'weight-loss', label: 'Weight Loss', greekLabel: 'απώλεια βάρους' },
      { value: 'child-nutrition', label: 'Child Nutrition', greekLabel: 'παιδική διατροφή' },
      { value: 'pregnancy', label: 'Pregnancy', greekLabel: 'εγκυμοσύνη' },
      { value: 'obesity', label: 'Obesity', greekLabel: 'παχυσαρκία' },
      { value: 'breastfeeding', label: 'Breastfeeding', greekLabel: 'θηλασμός' },
      { value: 'diabetes', label: 'Diabetes', greekLabel: 'διαβήτης' },
      {
        value: 'nutrigenetics',
        label: 'Nutrigenetics',
        greekLabel: ' nutrigenetics-διατροφογενετική',
      },
      { value: 'emotional-eating', label: 'Emotional eating', greekLabel: 'Emotional eating' },
      {
        value: 'intermittent-fasting',
        label: 'Intermittent fasting',
        greekLabel: 'διαλειμματική διατροφή',
      },
      { value: 'endomitriosis', label: 'Endomitriosis', greekLabel: 'ενδομητρίωση' },
      {
        value: 'eating-disorders',
        label: 'Eating disorders',
        greekLabel: 'διατροφικές διαταραχές',
      },
    ],
  },
  // {
  //   key: 'homeopathist-chinese-medicine',
  //   label: 'Homeopathist - Chinese medicine',
  //   greekLabel: 'Ομοιοπαθητικός - Κινέζικη Ιατρική',
  //   options: [],
  // },
  // {
  //   key: 'career-coach',
  //   label: 'Career Coach',
  //   greekLabel: 'Σύμβουλος καριέρας',
  //   options: [
  //     { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
  //     { value: 'pregnancy', label: 'Pregnancy', greekLabel: 'εγκυμοσύνη' },
  //     {
  //       value: 'professional-counseling',
  //       label: 'Professional Counseling',
  //       greekLabel: 'επαγγελματική συμβουλευτική',
  //     },
  //     { value: 'self-awareness', label: 'Self-awareness', greekLabel: 'αυτογνωσία' },
  //   ],
  // },
  // {
  //   key: 'head-neck-surgeon',
  //   label: 'Head & neck surgeon',
  //   greekLabel: 'Χειρουργός κεφαλής & τραχήλου',
  //   options: [
  //     { value: 'cancer', label: 'Cancer', greekLabel: 'καρκίνος' },
  //     { value: 'hpv', label: 'HPV', greekLabel: 'HPV' },
  //     { value: 'endocrinology-surgery', label: 'Endocrinology Surgery', greekLabel: 'ενδοκρινολογία' },
  //     { value: 'oncology-surgery', label: 'oncology surgery', greekLabel: 'ογκολογία κεφαλής και τραχήλου' },
  //     { value: 'oncology-surgery', label: 'oncology surgery', greekLabel: 'ογκολογία κεφαλής και τραχήλου' },
  //   ],
  // },
  // {
  //   key: 'genetics-counselor',
  //   label: 'Genetics Counselor',
  //   greekLabel: 'Γενετιστής',
  //   options: [],
  // },
  // {
  //   key: 'acupuncturist',
  //   label: 'Acupuncturist',
  //   greekLabel: 'Βελονιστής',
  //   options: [
  //     { value: 'pregnancy', label: 'Pregnancy', greekLabel: 'εγκυμοσύνη' },
  //     { value: 'depression', label: 'Depression', greekLabel: 'κατάθλιψη' },
  //     { value: 'obesity', label: 'Obesity', greekLabel: 'παχυσαρκία' },
  //     { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
  //     { value: 'fertility', label: 'Fertility', greekLabel: 'γονιμότητα' },
  //     { value: 'smoking', label: 'Smoking', greekLabel: 'κάπνισμα' },
  //   ],
  // },
  // {
  //   key: 'reiki-expert',
  //   label: 'Reiki expert',
  //   greekLabel: 'Ειδικός Reiki',
  //   options: [],
  // },
  {
    key: 'reproductive-rheumatologist',
    label: 'Reproductive Rheumatologist',
    greekLabel: 'Ρευματολόγος',
    options: [
      {
        value: 'reproduction-fertility',
        label: 'Reproduction & Fertility',
        greekLabel: 'αναπαραγωγή & γονιμότητα',
      },
      {
        value: 'rheumatoid-arthritis',
        label: 'Rheumatoid Arthritis',
        greekLabel: 'ρευματοειδής αρθρίτιδα',
      },
      { value: 'spondyloarthritis', label: 'Spondyloarthritis', greekLabel: 'σπονδυλοαρθρίτιδες' },
      {
        value: 'crystalline-arthritis',
        label: 'Crystalline Arthritis',
        greekLabel: 'κρυσταλλική αρθρίτιδα',
      },
      {
        value: 'systemic-lupus-erythematosus',
        label: 'Systemic Lupus Erythematosus',
        greekLabel: 'συστηματικός ερυθηματώδης λύκος',
      },
      {
        value: 'systemic-sclerosis',
        label: 'Systemic Sclerosis',
        greekLabel: 'συστηματική σκλήρυνση',
      },
      { value: 'sjogrens-disease', label: "Sjögren's Disease", greekLabel: 'νόσος του Sjögren' },
      { value: 'vasculitis', label: 'Vasculitis', greekLabel: 'αγγειίτιδες' },
      {
        value: 'polymyalgia-rheumatica',
        label: 'Polymyalgia Rheumatica',
        greekLabel: 'ρευματική πολυμυαλγία',
      },
      { value: 'osteoarthritis', label: 'Osteoarthritis', greekLabel: 'οστεοαρθρίτιδα' },
      { value: 'osteoporosis', label: 'Osteoporosis', greekLabel: 'οστεοπόρωση' },
      { value: 'fibromyalgia', label: 'Fibromyalgia', greekLabel: 'ινομυαλγία' },
    ],
  },
  {
    key: 'orthopaedic',
    label: 'Orthopaedic',
    greekLabel: 'Ορθοπαιδικός',
    options: [
      { value: 'tendonitis', label: 'Tendonitis', greekLabel: 'τενοντίτιδα' },
      { value: 'osteoarthritis', label: 'Osteoarthritis', greekLabel: 'οστεοαρθρίτιδα' },
      { value: 'cervical-syndrome', label: 'Cervical Syndrome', greekLabel: 'αυχενικό σύνδρομο' },
      { value: 'sciatica', label: 'Sciatica', greekLabel: 'ισχιαλγία' },
      {
        value: 'pediatric-orthopedics',
        label: 'Pediatric Orthopedics',
        greekLabel: 'παιδοορθοπαιδική',
      },
      { value: 'herniated-disc', label: 'Herniated Disc', greekLabel: 'δισκοκήλη' },
      { value: 'osteoporosis', label: 'Osteoporosis', greekLabel: 'οστεοπόρωση' },
      { value: 'kyphosis', label: 'Kyphosis', greekLabel: 'κύφωση' },
      { value: 'scoliosis', label: 'Scoliosis', greekLabel: 'σκολίωση' },
      {
        value: 'knee-or-hip-replacement',
        label: 'Knee or Hip Replacement',
        greekLabel: 'αρθροπλαστική γόνατος ή ισχίου',
      },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
    ],
  },
  {
    key: 'neurologist',
    label: 'Neurologist',
    greekLabel: 'Νευρολόγος',
    options: [
      { value: 'strokes', label: 'Strokes', greekLabel: 'αγγειακά εγκεφαλικά επεισόδια' },
      { value: 'dementia', label: 'Dementia', greekLabel: 'άνοια' },
      { value: 'parkinsons', label: "Parkinson's", greekLabel: 'πάρκινσον' },
      { value: 'epilepsy', label: 'Epilepsy', greekLabel: 'επιληψία' },
      {
        value: 'multiple-sclerosis',
        label: 'Multiple Sclerosis',
        greekLabel: 'σκλήρυνση κατά πλάκας',
      },
      { value: 'myasthenia-gravis', label: 'Myasthenia Gravis', greekLabel: 'μυασθένεια' },
      { value: 'migraine', label: 'Migraine', greekLabel: 'ημικρανία' },
      { value: 'vertigo', label: 'Vertigo', greekLabel: 'ίλιγγος' },
      { value: 'polyneuropathies', label: 'Polyneuropathies', greekLabel: 'πολυνευροπάθειες' },
      { value: 'sleep-disorders', label: 'Sleep Disorders', greekLabel: 'διαταραχές ύπνου' },
      { value: 'headache', label: 'Headache', greekLabel: 'κεφαλαλγία' },
      { value: 'alzheimers-disease', label: "Alzheimer's Disease", greekLabel: 'νόσος Αλτσχάιμερ' },
      { value: 'parkinsons-disease', label: "Parkinson's Disease", greekLabel: 'νόσος Πάρκινσον' },
    ],
  },
  {
    key: 'pediatric-surgeon',
    label: 'Pediatric Surgeon',
    greekLabel: 'Χειρουργός Παίδων',
    options: [
      {
        value: 'testicular',
        label: 'Testicular descent abnormalities',
        greekLabel: 'Ανωμαλίες καθόδου των όρχεων',
      },
      {
        value: 'groin',
        label: 'Groin and scrotum swelling',
        greekLabel: 'Διόγκωση βουβωνικής χώρας και οσχέου',
      },
      { value: 'varicose', label: 'Varicose veins', greekLabel: 'Κιρσοκήλη' },
      { value: 'cyst', label: 'Cyst', greekLabel: 'Κύστη' },
      { value: 'penile-disorders', label: 'Penile disorders', greekLabel: 'Παθήσεις του πέους' },
      { value: 'head-injury', label: 'Head injury', greekLabel: 'Κάκωση κεφαλής' },
      { value: 'cryptorchidism', label: 'Cryptorchidism', greekLabel: 'Κρυψορχία' },
      { value: 'urology', label: 'Pediatric urology', greekLabel: 'Παιδοουρολογία' },
      { value: 'circumcision', label: 'Circumcision', greekLabel: 'Περιτομή' },
      { value: 'phimosis', label: 'Phimosis', greekLabel: 'Φίμωση' },
      {
        value: 'lip-bridle',
        label: 'Short upper lip bridle',
        greekLabel: 'Βραχύς χαλινός άνω χείλους',
      },
      {
        value: 'tongue-bridle',
        label: 'Short tongue bridle',
        greekLabel: 'Βραχύς χαλινός γλώσσας',
      },
      { value: 'pediatric-surgery', label: 'Pediatric surgery', greekLabel: 'Παιδοχειρουργική' },
      { value: 'appendicitis', label: 'Appendicitis', greekLabel: 'Σκωληκοειδίτιδα' },
    ],
  },
  {
    key: 'health-and-wellness-coach',
    label: 'Health & Wellness Coach',
    greekLabel: 'Σύμβουλος Υγείας και Ευεξίας',
    options: [
      {
        value: 'menopause',
        label: 'Menopause',
        greekLabel: 'Εμμηνόπαυση',
      },
      {
        value: 'stress',
        label: 'Stress',
        greekLabel: 'Άγχος',
      },
      {
        value: 'wellness',
        label: 'Wellness',
        greekLabel: 'Ευεξία',
      },
      {
        value: 'mindfulness',
        label: 'Mindfulness',
        greekLabel: 'Mindfulness',
      },
      {
        value: 'CBT',
        label: 'Cognitive Behavioral Therapy (CBT)',
        greekLabel: 'CBT',
      },
      {
        value: 'effective-thinking',
        label: 'Effective Thinking',
        greekLabel: 'Αποτελεσματική σκέψη',
      },
      {
        value: 'meditation',
        label: 'Meditation',
        greekLabel: 'Διαλογισμός',
      },
      {
        value: 'divorce',
        label: 'Divorce',
        greekLabel: 'Διαζύγιο',
      },
      {
        value: 'postpartum-depression',
        label: 'Postpartum depression',
        greekLabel: 'Επιλόχειος κατάθλιψη',
      },
      {
        value: 'ADHD',
        label: 'Attention Deficit Hyperactivity Disorder (ADHD)',
        greekLabel: 'ΔΕΠΥ',
      },
      {
        value: 'bipolar',
        label: 'Bipolar Disorder',
        greekLabel: 'Διπολική Διαταραχή',
      },
      {
        value: 'sex-therapy',
        label: 'Sex Therapy',
        greekLabel: 'Σεξουαλική θεραπεία',
      },
      {
        value: 'couples-therapy',
        label: 'Couples therapy',
        greekLabel: 'Θεραπεία ζευγαριών',
      },
      {
        value: 'addiction',
        label: 'Addiction',
        greekLabel: 'Εθισμός',
      },
      {
        value: 'teenager-consulting',
        label: 'Teenager Consulting',
        greekLabel: 'Συμβουλευτική εφήβων',
      },
      {
        value: 'parenting-consulting',
        label: 'Parenting Consulting',
        greekLabel: 'Συμβουλευτική γονέων',
      },
      {
        value: 'sexual-disorders',
        label: 'Sexual Disorders',
        greekLabel: 'Σεξουαλικές διαταραχές',
      },
      {
        value: 'endometriosis',
        label: 'Endometriosis',
        greekLabel: 'Ενδομητρίωση',
      },
      {
        value: 'panic-disorder',
        label: 'Panic Disorder',
        greekLabel: 'Διαταραχή πανικού',
      },
      {
        value: 'orgasm-sexual-pleasure',
        label: 'Orgasm & Sexual Pleasure',
        greekLabel: 'Οργασμός και σεξουαλική ευχαρίστηση',
      },
      {
        value: 'grief',
        label: 'Grief',
        greekLabel: 'Θλίψη',
      },
      {
        value: 'gender-dysphoria',
        label: 'Gender Dysphoria',
        greekLabel: 'Δυσφορία φύλου',
      },
      {
        value: 'lack-of-sexual-desire',
        label: 'Lack of Sexual Desire',
        greekLabel: 'Έλλειψη σεξουαλικής επιθυμίας',
      },
      {
        value: 'puberty',
        label: 'Puberty',
        greekLabel: 'Εφηβεία',
      },
      {
        value: 'parenthood',
        label: 'Parenthood',
        greekLabel: 'Η γονεϊκότητα',
      },
      {
        value: 'learning-difficulties',
        label: 'Learning Difficulties',
        greekLabel: 'Μαθησιακές δυσκολίες',
      },
      {
        value: 'problems-at-school',
        label: 'Problems at School',
        greekLabel: 'Προβλήματα στο σχολείο',
      },
      {
        value: 'aggression-in-children',
        label: 'Aggression in children',
        greekLabel: 'Επιθετικότητα στα παιδιά',
      },
      {
        value: 'putting-limits-in-children',
        label: 'Putting Limits in Children',
        greekLabel: 'Βάζοντας όρια στα παιδιά',
      },
      {
        value: 'toddlers',
        label: 'Toddlers',
        greekLabel: 'Νήπια',
      },
      {
        value: 'ADHD',
        label: 'Attention Deficit Hyperactivity Disorder (ADHD)',
        greekLabel: 'Διάσπαση προσοχής-Υπερκινητικότητα',
      },
      {
        value: 'antisocial-behavior',
        label: 'Antisocial behaviour',
        greekLabel: 'Αντικοινωνική συμπεριφορά',
      },
      {
        value: 'anger-outbursts',
        label: 'Anger outbursts',
        greekLabel: 'Ξεσπάσματα θυμού',
      },
      {
        value: 'child-isolation',
        label: 'Child Isolation',
        greekLabel: 'Παιδική Απομόνωση',
      },
      {
        value: 'school-bullying',
        label: 'School Bullying',
        greekLabel: 'Σχολικός εκφοβισμός',
      },
      {
        value: 'oppositional-defiant-disorder-odd',
        label: 'Oppositional defiant disorder (ODD)',
        greekLabel: 'Αντιπολιτευτική προκλητική διαταραχή (ODD)',
      },
      {
        value: 'self-care',
        label: 'Self-Care',
        greekLabel: 'Αυτοφροντίδα',
      },
      {
        value: 'work-life-balance',
        label: 'Work-Life Balance',
        greekLabel: 'Work-Life Balance',
      },
      {
        value: 'holistic-health',
        label: 'Holistic Health',
        greekLabel: 'Ολιστική Υγεία',
      },
      {
        value: 'change-of-habits',
        label: 'Change of habits',
        greekLabel: 'Αλλαγή συνηθειών',
      },
      {
        value: 'detoxification',
        label: 'Detoxification (Clean Living)',
        greekLabel: 'Αποτοξίνωση (clean living)',
      },
    ],
  },
  {
    value: 'social-worker',
    label: 'Social Worker',
    greekLabel: 'Κοινωνικός Λειτουργός',
    options: [
      {
        value: 'social-welfare',
        label: 'Social Welfare',
        greekLabel: 'Κοινωνική Πρόνοια',
      },
      {
        value: 'delinquency',
        label: 'Delinquency',
        greekLabel: 'Παραβατικότητα',
      },
      {
        value: 'child-protection',
        label: 'Child Protection',
        greekLabel: 'Παιδική Προστασία',
      },
      {
        value: 'elderly-care',
        label: 'Elderly Care',
        greekLabel: 'Φροντίδα Ηλικιωμένων',
      },
      {
        value: 'rehabilitation',
        label: 'Rehabilitation',
        greekLabel: 'Απεξάρτηση',
      },
      {
        value: 'child-abuse',
        label: 'Child Abuse',
        greekLabel: 'Κακοποίηση Παιδιών',
      },
      {
        value: 'immigrant-support',
        label: 'Immigrant Support',
        greekLabel: 'Υποστήριξη Μεταναστών',
      },
      {
        value: 'single-parent-family',
        label: 'Single Parent Family',
        greekLabel: 'Μονογονεϊκή Οικογένεια',
      },
    ],
  },
  {
    key: 'general-practitioner',
    label: 'General Practitioner',
    greekLabel: 'Παθολόγος',
    options: [
      {
        value: 'vaccines',
        label: 'Vaccines',
        greekLabel: 'Εμβόλια',
      },
      {
        value: 'flu',
        label: 'Flu',
        greekLabel: 'Γρίπη',
      },
      {
        value: 'COVID',
        label: 'COVID',
        greekLabel: 'COVID',
      },
      {
        value: 'strep',
        label: 'Strep',
        greekLabel: 'Στρεπτόκοκκος',
      },
      {
        value: 'illnesses',
        label: 'Illnesses',
        greekLabel: 'Ασθένειες',
      },
      {
        value: 'diabetes',
        label: 'Diabetes',
        greekLabel: 'Διαβήτης',
      },
      {
        value: 'cough',
        label: 'Cough',
        greekLabel: 'Βήχας',
      },
      {
        value: 'stomach-ache',
        label: 'Stomach Ache',
        greekLabel: 'Πόνος στην κοιλιά',
      },
      {
        value: 'headache',
        label: 'Headache',
        greekLabel: 'Πονοκέφαλος',
      },
      {
        value: 'fever',
        label: 'Fever',
        greekLabel: 'Πυρετός',
      },
      {
        value: 'asthma',
        label: 'Asthma',
        greekLabel: 'Άσθμα',
      },
      {
        value: 'cholesterol',
        label: 'Cholesterol',
        greekLabel: 'Χοληστερίνη',
      },
      {
        value: 'UTI',
        label: 'Urinary Tract Infection (UTI)',
        greekLabel: 'Ουρολοίμωξη',
      },
      {
        value: 'respiratory-infections',
        label: 'Respiratory Infections',
        greekLabel: 'Λοιμώξεις Αναπνευστικού',
      },
      {
        value: 'high-blood-pressure',
        label: 'High Blood Pressure',
        greekLabel: 'Υψηλή Πίεση',
      },
      {
        value: 'sexually-transmitted-diseases',
        label: 'Sexually Transmitted Diseases (STDs)',
        greekLabel: 'Σεξουαλικώς Μεταδιδόμενα Νοσήματα (ΣΜΝ)',
      },
    ],
  },
  {
    key: 'general-surgeon',
    label: 'General Surgeon',
    greekLabel: 'Γενικός Χειρουργός',
    options: [
      { value: 'anus-diseases', label: 'Diseases of the anus', greekLabel: 'Παθήσεις πρωκτού' },
      { value: 'hemorrhoids', label: 'Haemorrhoids', greekLabel: 'Αιμορροΐδες' },
      { value: 'ring-fissure', label: 'Ring fissure', greekLabel: 'Ραγάδα δακτυλίο' },
      { value: 'anal-fistula', label: 'Anal fistula', greekLabel: 'Συρίγγια πρωκτού' },
      { value: 'coccygeal-cyst', label: 'Coccygeal cyst', greekLabel: 'Κύστη κόκκυγος' },
      { value: 'umbilical-hernia', label: 'Umbilical hernia', greekLabel: 'ομφαλοκήλη' },
    ],
  },
  {
    key: 'head-neck-surgeon',
    label: 'Head & Neck Surgeon',
    greekLabel: 'Χειρουργός Κεφαλής & Τραχήλου',
    options: [
      { value: 'cancer', label: 'Cancer', greekLabel: 'Καρκίνος' },
      { value: 'hpv', label: 'HPV', greekLabel: 'HPV' },
      {
        value: 'endocrinology-surgery',
        label: 'Endocrinology Surgery',
        greekLabel: 'Ενδοκρινολογία',
      },
      {
        value: 'oncology-surgery',
        label: 'Oncology Surgery',
        greekLabel: 'Ογκολογία Κεφαλής & Τραχήλου',
      },
      { value: 'thyroid', label: 'Thyroid', greekLabel: 'Θυρεοειδής' },
      { value: 'skin-cancer', label: 'Skin Cancer', greekLabel: 'Καρκίνος Δέρματος' },
      { value: 'melanoma', label: 'Melanoma', greekLabel: 'Μελάνωμα' },
      {
        value: 'thyroid-parathyroid-gland-surgery',
        label: 'Thyroid and Parathyroid Gland Surgery',
        greekLabel: 'Χειρουργική επέμβαση θυρεοειδούς και παραθυρεοειδούς αδένα',
      },
      {
        value: 'salivary-gland-surgery',
        label: 'Salivary Gland Surgery',
        greekLabel: 'Χειρουργική στόματος',
      },
      {
        value: 'mout-and-pharynx-syrgery',
        label: 'Mout and pharynx syrgery',
        greekLabel: 'στοματοφάρυγγα και λάρυγγα',
      },
      { value: 'robotic-surgery', label: 'Robotic Surgery', greekLabel: 'Ρομποτική χειρουργική' },
      {
        value: 'skin-cancer-surgery',
        label: 'Skin cancer surgery',
        greekLabel: 'Χειρουργική καρκίνου του δέρματος ',
      },
    ],
  },
  {
    key: 'cardiologist',
    label: 'Cardiologist',
    greekLabel: 'Καρδιολόγος',
    options: [
      { value: 'arrhythmias', label: 'Arrhythmias', greekLabel: 'αρρυθμίες' },
      {
        value: 'fainting-episodes',
        label: 'Fainting Episodes',
        greekLabel: 'λιποθυμικά επεισόδια',
      },
      { value: 'heart-failure', label: 'Heart Failure', greekLabel: 'καρδιακή ανεπάρκεια' },
      {
        value: 'coronary-artery-disease',
        label: 'Coronary Artery Disease',
        greekLabel: 'στεφανιαία νόσος',
      },
      { value: 'pregnancy', label: 'Pregnancy', greekLabel: 'εγκυμοσύνη' },
      { value: 'aneurysm', label: 'Aneurysm', greekLabel: 'ανεύρυσμα' },
      { value: 'cholesterol', label: 'Cholesterol', greekLabel: 'χοληστερίνη' },
      { value: 'menopause', label: 'Menopause', greekLabel: 'εμμηνόπαυση' },
    ],
  },
  {
    key: 'other',
    label: 'Other',
    greekLabel: 'Άλλο',
    options: [],
  },
];

const citiesInEnglish = [
  'Athens',
  'Thessaloniki',
  'Patras',
  'Piraeus',
  'Heraklion',
  'Larissa',
  'Peristeri',
  'Acharnes',
  'Kallithea',
  'Kalamaria',
  'Glyfada',
  'Nikaia',
  'Volos',
  'Ilion',
  'Evosmos',
  'Chalandri',
  'Ilioupoli',
  'Keratsini',
  'Nea Smyrni',
  'Marousi',
  'Agios Dimitrios',
  'Zografou',
  'Egaleo',
  'Ioannina',
  'Palaio Faliro',
  'Nea Ionia',
  'Chalcis',
  'Agia Paraskevi',
  'Trikala',
  'Korydallos',
  'Petroupoli',
  'Alexandroupoli',
  'Serres',
  'Katerini',
  'Vyronas',
  'Kalamata',
  'Xanthi',
  'Galatsi',
  'Rhodes',
  'Chania',
  'Komotini',
  'Kavala',
  'Agrinio',
  'Irakleio',
  'Kifissia',
  'Lamia',
  'Chaidari',
  'Stavroupoli',
  'Drama',
  'Kozani',
  'Veria',
  'Alimos',
  'Karditsa',
  'Polichni',
  'Pylaia',
  'Ampelokipoi',
  'Rethymno',
  'Sykies',
  'Ano Liosia',
  'Gerakas',
  'Mytilene',
  'Argyroupoli',
  'Vrilissia',
  'Agioi Anargyroi',
  'Nea Ionia',
  'Ptolemaida',
  'Voula',
  'Aspropyrgos',
  'Cholargos',
  'Koropi',
  'Corinth',
  'Corfu',
  'Giannitsa',
  'Tripoli',
  'Metamorfosi',
  'Kamatero',
  'Megara',
  'Chios',
  'Agia Varvara',
  'Kaisariani',
  'Eleftherio-Kordelio',
  'Pyrgos',
  'Neapoli',
  'Perama',
  'Moschato',
  'Nea Filadelfeia',
  'Salamina',
  'Eleusis',
  'Kilkis',
  'Arta',
  'Oraiokastro',
  'Melissia',
  'Pallini',
  'Dafni',
  'Livadeia',
  'Artemida',
  'Argos',
  'Thebes',
  'Kos',
  'Pefki',
  'Preveza',
  'Aigio',
  'Orestiada',
  'Thermi',
  'Nea Erythraia',
  'Amaliada',
  'Nea Makri',
  'Naousa',
  'Edessa',
  'Sparta',
  'Kalymnos',
  'Ierapetra',
  'Panorama',
  'Florina',
  'Peraia',
  'Vari',
  'Other',
  'Elliniko',
  'Kastoria',
  'Gazi',
  'Paiania',
  'Alexandreia',
  'Kalyvia Thorikou',
  'Efkarpia',
  'Agios Ioannis Renti',
  'Rafina',
  'Nafplio',
  'Tavros',
  'Menemeni',
  'Missolonghi',
  'Papagou',
  'Loutraki',
  'Drapetsona',
  'Agios Nikolaos',
  'Pefka',
  'Nea Alikarnassos',
  'Spata',
  'Nafpaktos',
  'Ialysos',
  'Grevena',
  'Mandra',
  'Glyka Nera',
  'Diavata',
  'Tyrnavos',
  'Sitia',
  'Ermoupoli',
  'Argostoli',
  'Neo Psychiko',
  'Agios Stefanos',
  'Ymittos',
  'Anatoli',
  'Igoumenitsa',
  'Nea Artaki',
];

const citiesInGreek = [
  'Αθήνα',
  'Θεσσαλονίκη',
  'Πάτρα',
  'Πειραιάς',
  'Ηράκλειο',
  'Λάρισα',
  'Περιστέρι',
  'Αχαρνές',
  'Καλλιθέα',
  'Καλαμαριά',
  'Γλυφάδα',
  'Νίκαια',
  'Βόλος',
  'Ίλιον',
  'Έβοσμος',
  'Χαλάνδρι',
  'Ηλιούπολη',
  'Κερατσίνι',
  'Νέα Σμύρνη',
  'Μαρούσι',
  'Άγιος Δημήτριος',
  'Ζωγράφου',
  'Αιγάλεω',
  'Ιωάννινα',
  'Παλαιό Φάληρο',
  'Νέα Ιωνία',
  'Χαλκίδα',
  'Αγία Παρασκευή',
  'Τρίκαλα',
  'Κορυδαλλός',
  'Πετρούπολη',
  'Αλεξανδρούπολη',
  'Σέρρες',
  'Κατερίνη',
  'Βύρωνας',
  'Καλαμάτα',
  'Ξάνθη',
  'Γαλάτσι',
  'Ρόδος',
  'Χανιά',
  'Κομοτηνή',
  'Καβάλα',
  'Αγρίνιο',
  'Ηράκλειο',
  'Κηφισιά',
  'Λαμία',
  'Χαϊδάρι',
  'Σταυρούπολη',
  'Δράμα',
  'Κοζάνη',
  'Βέροια',
  'Άλιμος',
  'Καρδίτσα',
  'Πολίχνη',
  'Πυλαία',
  'Αμπελόκηποι',
  'Ρέθυμνο',
  'Συκιές',
  'Άνω Λιόσια',
  'Γέρακας',
  'Μυτιλήνη',
  'Αργυρούπολη',
  'Βριλήσσια',
  'Άγιοι Ανάργυροι',
  'Νέα Ιωνία',
  'Πτολεμαΐδα',
  'Βούλα',
  'Ασπρόπυργος',
  'Χολαργός',
  'Κορωπί',
  'Κόρινθος',
  'Κέρκυρα',
  'Γιαννιτσά',
  'Τρίπολη',
  'Μεταμόρφωση',
  'Καματερό',
  'Μέγαρα',
  'Χίος',
  'Αγία Βαρβάρα',
  'Καισαριανή',
  'Ελευθέριο-Κορδελιό',
  'Πύργος',
  'Νεάπολη',
  'Πέραμα',
  'Μοσχάτο',
  'Νέα Φιλαδέλφεια',
  'Σαλαμίνα',
  'Ελευσίνα',
  'Κιλκίς',
  'Άρτα',
  'Ωραιόκαστρο',
  'Μελίσσια',
  'Παλλήνη',
  'Δάφνη',
  'Λιβαδειά',
  'Αρτέμιδα',
  'Άργος',
  'Θήβα',
  'Κως',
  'Πεύκη',
  'Πρέβεζα',
  'Αίγιο',
  'Άλλο',
  'Ορεστιάδα',
  'Θέρμη',
  'Νέα Ερυθραία',
  'Αμαλιάδα',
  'Νέα Μάκρη',
  'Νάουσα',
  'Έδεσσα',
  'Σπάρτη',
  'Κάλυμνος',
  'Ιεράπετρα',
  'Πανόραμα',
  'Φλώρινα',
  'Περαία',
  'Βάρη',
  'Ελληνικό',
  'Καστοριά',
  'Γάζι',
  'Παιανία',
  'Αλεξάνδρεια',
  'Καλύβια Θορικού',
  'Ευκαρπία',
  'Άγιος Ιωάννης Ρέντη',
  'Ραφήνα',
  'Ναύπλιο',
  'Ταύρος',
  'Μενεμένη',
  'Μεσολόγγι',
  'Παπάγου',
  'Λουτράκι',
  'Δραπετσώνα',
  'Άγιος Νικόλαος',
  'Πεύκα',
  'Νέα Αλικαρνασσός',
  'Σπάτα',
  'Ναύπακτος',
  'Ιαλυσός',
  'Γρεβενά',
  'Μάνδρα',
  'Γλυκά Νερά',
  'Διαβατά',
  'Τύρναβος',
  'Σητεία',
  'Ερμούπολη',
  'Αργοστόλι',
  'Νέο Ψυχικό',
  'Άγιος Στέφανος',
  'Υμηττός',
  'Ανατολή',
  'Ηγουμενίτσα',
  'Νέα Αρτάκη',
];
export const sortGreekCities = (data, locale) => {
  return data.sort((a, b) => new Intl.Collator(locale).compare(a, b));
};

const defaultLocale = (typeof window !== 'undefined' && localStorage.getItem('locale')) || 'el';
const isGreek = defaultLocale === 'el';

export const citiesOptions = (isPrefLangGreek = true) => {
  return isGreek || isPrefLangGreek
    ? sortGreekCities(citiesInGreek, 'el')
    : sortGreekCities(citiesInEnglish, 'en');
};

export const offerSessionInOptions = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'German' },
  { value: 'fr', label: 'French' },
  { value: 'it', label: 'Italian' },
  { value: 'el', label: 'Greek' },
];

export const offerSessionInOptionsInGreek = [
  { value: 'en', label: 'Αγγλικά' },
  { value: 'de', label: 'Γερμανικά' },
  { value: 'fr', label: 'Γαλλικά' },
  { value: 'it', label: 'Ιταλικά' },
  { value: 'el', label: 'Ελληνικά' },
];

export const patientHomepageCategories = [
  { label: 'Mental health', key: 'mental-health', greekLabel: 'Ψυχική υγεία' },
  { label: 'Nutrition', key: 'nutrition', greekLabel: 'Διατροφή' },
  {
    label: 'Fertility, IVF & Adoption',
    key: 'fertility-ivf-adoption',
    greekLabel: 'Γονιμότητα, Εξωσωματική Γονιμοποίηση & Υιοθεσία',
  },
  {
    label: 'Pregnancy & Postpartum',
    key: 'pregnancy-postpartum',
    greekLabel: 'Εγκυμοσύνη & Λοχεία',
  },
  {
    label: 'Gynecological & Sexual health',
    key: 'gynecological-sexual-health',
    greekLabel: 'Γυναικολογική & Σεξουαλική υγεία',
  },
  {
    label: 'Parenting & Paediatric',
    key: 'parenting-paediatric',
    greekLabel: 'Parenting & Παιδιατρικά θέματα',
  },
  {
    label: 'Endometriosis & Chronic diseases',
    key: 'endometriosis-chronic-diseases',
    greekLabel: 'Ενδομητρίωση & Χρόνιες παθήσεις',
  },
  { label: 'Antiaging & Beauty', key: 'antiaging-beauty', greekLabel: 'Αντιγήρανση & Ομορφιά' },
  { label: 'General health & more', key: 'general-health-more', greekLabel: 'Γενική υγεία & άλλα' },
];

export const symptomsCategories = [
  { label: 'Irregular cycles', greekLabel: 'Ανώμαλοι κύκλοι', key: 'irregular-cycles' },
  { label: 'Skipping cycles', greekLabel: 'Απώλεια περιόδου', key: 'skipping-cycles' },
  { label: 'Heavy periods', greekLabel: 'Βαριά περίοδος', key: 'heavy-periods' },
  { label: 'Long periods', greekLabel: 'Μακρές περίοδοι', key: 'long-periods' },
  { label: 'Headaches', greekLabel: 'Πονοκέφαλοι', key: 'headaches' },
  { label: 'Mood swings', greekLabel: 'Εναλλαγές διάθεσης', key: 'mood-swings' },
  { label: 'Anxiety', greekLabel: 'Άγχος', key: 'anxiety' },
  { label: 'Brain fog', greekLabel: 'Δυσκολία στην συγκέντρωση', key: 'brain-fog' },
  { label: 'Decrease of memory', greekLabel: 'Μειωμένη μνήμη', key: 'decrease-of-memory' },
  { label: 'Low energy', greekLabel: 'Χαμηλή ενέργεια', key: 'low-energy' },
  { label: 'Heart palpitations', greekLabel: 'Ταχυπαλμίες', key: 'heart-palpitations' },
  { label: 'Weight gain', greekLabel: 'Αύξηση βάρους', key: 'weight-gain' },
  { label: 'Belly fat', greekLabel: 'Λίπος στην κοιλιά', key: 'belly-fat' },
  { label: 'Water retention', greekLabel: 'Κατακράτηση', key: 'water-retention' },
  {
    label: 'Vaginal atrophy and dryness',
    greekLabel: 'Κολπική ατροφία και ξηρότητα',
    key: 'vaginal-atrophy-and-dryness',
  },
  { label: 'Dyspareunia', greekLabel: 'Δυσπαρεύνια', key: 'dyspareunia' },
  { label: 'Uterine prolapse', greekLabel: 'Πρόπτωση μήτρας', key: 'uterine-prolapse' },
  { label: 'Urinary incontinence', greekLabel: 'Ακράτεια', key: 'urinary-incontinence' },
  { label: 'Low libido', greekLabel: 'Χαμηλή λίμπιντο', key: 'low-libido' },
  { label: 'Hot flushes', greekLabel: 'Εξάψεις', key: 'hot-flushes' },
  { label: 'Insomnia', greekLabel: 'Αϋπνία', key: 'insomnia' },
  { label: 'Fatigue', greekLabel: 'Αίσθημα κόπωσης', key: 'fatigue' },
  { label: 'Joints pain', greekLabel: 'Πόνος στις αρθρώσεις', key: 'joints-pain' },
  { label: 'Frozen shoulder', greekLabel: 'Παγωμένος ώμος', key: 'frozen-shoulder' },
  { label: 'Bloating', greekLabel: 'Φούσκωμα', key: 'bloating' },
  { label: 'Dry skin', greekLabel: 'Ξηρότητα δέρματος', key: 'dry-skin' },
  { label: 'Depression', greekLabel: 'Κατάθλιψη', key: 'depression' },
  {
    label: 'Empty nest syndrome',
    greekLabel: 'Σύνδρομο άδειας φωλιάς',
    key: 'empty-nest-syndrome',
  },
  { label: 'Loss of motivation', greekLabel: 'Αίσθημα ματαιότητας', key: 'loss-of-motivation' },
  { label: 'Night sweats', greekLabel: 'Νυχτερινές εφιδρώσεις', key: 'night-sweats' },
  {
    label: 'Decreased bone density',
    greekLabel: 'Μείωση της οστικής πυκνότητας',
    key: 'decreased-bone-density',
  },
];
