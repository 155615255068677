import React from 'react';
import { Modal } from 'rsuite';
import PropTypes from 'prop-types';
import '../../styles/rsuite.min.css';
import { VimePlayer } from '..';
import { useIntl } from 'react-intl';
import css from './VideoPlayerModal.module.css';
const VideoPlayerModal = ({ isOpen, toggleModal, guideData, viewport }) => {
  const intl = useIntl();
  return (
    <Modal open={isOpen} onClose={toggleModal} size="lg">
      <Modal.Header>
        <Modal.Title>{guideData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={css.videoPlayerWrapper}>
          {guideData?.videoURL ? (
            <VimePlayer
              videoURL={guideData?.videoURL}
              poster={guideData?.thumbnail}
              title={guideData?.title}
              intl={intl}
              viewport={viewport}
            />
          ) : (
            <p>No video URL provided.</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

VideoPlayerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired, // Boolean to control modal visibility
  toggleModal: PropTypes.func.isRequired, // Function to toggle modal visibility
  videoUrl: PropTypes.string, // URL of the video to be displayed
};

export default VideoPlayerModal;
