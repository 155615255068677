import { queryUsersData } from '../../util/api';

// ================ Action types ================ //

export const FETCH_USERS_REQUEST = 'app/VouchersPage/FETCH_USERS_REQUEST';
export const SEARCH_USERS_SUCCESS = 'app/VouchersPage/SEARCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'app/VouchersPage/FETCH_USERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  usersData: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USERS_REQUEST:
      return { ...state, fetchInProgress: true };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        usersData: payload.data,
        fetchInProgress: false,
      };
    case FETCH_USERS_ERROR:
      return { ...state, fetchInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_USERS_REQUEST });

export const searchUsersSuccess = response => ({
  type: SEARCH_USERS_SUCCESS,
  payload: { data: response.sellersData },
});

const fetchOrdersOrSalesError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = params => async (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  try {
    const usersData = await queryUsersData({});
    dispatch(searchUsersSuccess(usersData));
    return usersData;
  } catch (error) {
    // Dispatch an error action here
    dispatch(fetchOrdersOrSalesError(error));
  }
};
